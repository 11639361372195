import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';
import { firebaseConfig } from './config/firebaseConfig';
import { IS_TESTING } from './config/index';

let messaging = null;

if ('serviceWorker' in navigator && !IS_TESTING && isSupported()) {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} else {
  console.log('Service worker is not supported');
}

export { messaging };
