import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { API_URL } from '../../../config';
import { API_PATH } from '../../../config/api-paths';
import { TESTING_QTNS } from '../../../config/enums';
// Fixtures
import orderData from './orders.json';
import orgData from './organization.json';
import orgData2 from './organization2.json';

const orders = orderData.orders;
const { delivery, pickup } = orders;

const { orgKey } = orgData;
const { orgKey: orgKey2 } = orgData2;

const AxiosMockInstance = axios.create({
  baseURL: API_URL,
});

const mock = new MockAdapter(AxiosMockInstance);

/**
 * Mocked responses
 */

// -----------------------------------------------------

//ORGANIZATIONS

// Mock can reschedule but cannot change timeframe
mock.onGet(`${API_PATH.ORGANIZATION}`).reply(config => {
  console.log('config', config);
  if (config.params.organizationKey === orgKey2) {
    return [200, { data: orgData2 }];
  } else if (config.params.qtn === TESTING_QTNS.cantSelectTimeframe) {
    const orgTimeframe = orgData;
    orgTimeframe.ctaConfig.preparing.canSelectTimeframe = false;
    return [200, { data: orgTimeframe }];
  } else if (config.params.qtn === TESTING_QTNS.cantRescheduleDate) {
    const orgRescheduleDate = orgData;
    orgRescheduleDate.ctaConfig.preparing.canRescheduleDate = false;
    return [200, { data: orgRescheduleDate }];
  } else {
    return [200, { data: orgData }];
  }
});

// Preparing
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: '74ULN1', organizationKey: orgKey },
  })
  .reply(200, delivery.preparing);

mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'CANDAT', organizationKey: orgKey },
  })
  .reply(200, delivery.preparing);

mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'CANRED', organizationKey: orgKey },
  })
  .reply(200, delivery.preparing);

// Route started
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: '74ULN1', organizationKey: orgKey2 },
  })
  .reply(200, delivery.preparing);

// Preparing - with change of state after interval
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'LUCASG', organizationKey: orgKey },
  })
  .reply(200, delivery.preparing2);

setTimeout(() => {
  const toRouteStarted = delivery.preparing2;
  toRouteStarted.data.status = 'route_started';
  mock
    .onGet(`${API_PATH.TRACE}`, {
      params: { qtn: 'LUCASG', organizationKey: orgKey },
    })
    .reply(200, toRouteStarted);
}, 1000);

// Route started
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'KY9GC3', organizationKey: orgKey },
  })
  .reply(200, delivery.routeStarted);

// Route started failed
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'KY9GC4', organizationKey: orgKey },
  })
  .reply(200, delivery.failed);

// Arriving with live tracking
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'X7CTLY', organizationKey: orgKey },
  })
  .reply(200, delivery.arrivingWithLT);

// Arriving without live tracking
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'X7CTLN', organizationKey: orgKey },
  })
  .reply(200, delivery.arrivingWithoutLT);

// Delivered without feedback
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: '5UHLB2', organizationKey: orgKey },
  })
  .reply(200, delivery.deliveredWithoutFeedback);

mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'FEEDBK', organizationKey: orgKey },
  })
  .reply(200, delivery.deliveredWithoutFeedback);

// Delivered with feedback
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'A7BAB5', organizationKey: orgKey },
  })
  .reply(200, delivery.deliveredWithFeedback);

// -----------------------------------------------------

// Assigned
mock
  .onGet(`${API_PATH.TRACE}`, {
    params: { qtn: 'O1BAB5', organizationKey: orgKey },
  })
  .reply(200, pickup.assigned);

// -----------------------------------------------------

// mock.onGet(`${API_PATH.ORGANIZATION}`).reply(200, {
//   data: orgData,
// });

mock
  .onPost(`${API_PATH.FEEDBACK}`, {
    asymmetricMatch: function (actual) {
      return actual.qtn === 'FEEDBK';
    },
  })
  .reply(200, {
    result: false,
    success: false,
  })
  .onPost(`${API_PATH.FEEDBACK}`)
  .reply(200, { data: { result: true, success: true } });

mock
  .onPost(`${API_PATH.DISAGREE}`, {
    asymmetricMatch: function (actual) {
      return actual.qtn === '74ULN1';
    },
  })
  .reply(200, {
    data: {
      result: false,
      success: false,
    },
  })
  .onPost(`${API_PATH.DISAGREE}`)
  .reply(200, { data: { result: true, success: true } });

mock.onPost(`${API_PATH.DISAGREE}`).reply(200, { data: { result: true, success: true } });

mock.onGet(`${API_PATH.ADDRESS}`).reply(200, { data: { hasToReschedule: false } });

mock.onPost(`${API_PATH.ADDRESS}`).reply(config => {
  const address = JSON.parse(config.data).address;
  delivery.preparing2.data.delivery.address = address;
  return [200, delivery.preparing2];
});

//----------------------------- Config available dates -----------------------------------//

mock.onGet(`${API_PATH.DATE}`).reply(200, {
  data: {
    nonWorkDays: ['2022-04-08', '2022-04-13'],
    minRescheduleTime: '2022-04-07',
    maxRescheduleTime: '2022-04-12',
    weekDays: [true, true, true, true, true, false, false],
    timeframes: [
      {
        name: 'Franja horaria 1',
        from: '08:00',
        to: '12:00',
      },
      {
        name: 'Franja horaria 2',
        from: '13:00',
        to: '18:00',
      },
    ],
  },
});

mock.onPost(`${API_PATH.DATE}`).reply(200, {
  data: {
    success: true,
  },
});

mock.onGet(`${API_PATH.MOBILE}`).reply(200, {
  data: {
    url: 'https://tntmobileapp.page.link/cka2p1FHLkwkEmYM6',
  },
});
export { AxiosMockInstance };
