// Js Enums
const OPERATIONS = {
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
};

Object.freeze(OPERATIONS);

const STATES = {
  PREPARING: 'preparing',
  ASSIGNED: 'assigned',
  ROUTE_STARTED: 'route_started',
  ARRIVING: 'arriving',
  DELIVERED: 'delivered',
  FAILED: 'failed',
};

Object.freeze(STATES);

const DEV_HOSTS = {
  DEV: 'dev',
  LOCALHOST: 'localhost',
};

Object.freeze(DEV_HOSTS);

const TESTING_QTNS = {
  cantSelectTimeframe: 'CANDAT',
  cantRescheduleDate: 'CANRED',
};

export { OPERATIONS, STATES, DEV_HOSTS, TESTING_QTNS };
