import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    littleFace: {
      width: 102,
      height: 102,
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        width: 75,
        height: 75,
        padding: theme.spacing(0),
        marginLeft: theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
        width: 50,
        height: 50,
      },
    },
    iconActive: {
      fill: '#1473f7 !important',
    },
  }),
  { index: 1 },
);
