import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 12,
    padding: 30,
    boxShadow: '0px 4px 6px rgba(0,0,0,0.16)',
    borderRadius: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
      margin: 0,
      maxHeight: 600,
      boxShadow: 'none',
      borderTop: '1px solid  rgba(207,216,220, 1)',
      borderRadius: 0,
    },
  },
  cancelButton: {
    margin: 0,
    borderRadius: 16,
    width: '100%',
  },
  okButton: {
    margin: 0,
    marginLeft: 20,
    borderRadius: 16,
  },
  error: {
    fontSize: 12,
    color: red[500],
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    transform: 'translate(10px, -18px)',
  },
  input: {
    marginBottom: 20,
  },
}));
