import { Grid } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ColorlibConnector, ColorFailedConnector, muiTheme, useStyles } from './styles';
import Icons from '../Icons';

const TracingStepper = ({ steps, activeStep, isDelivery, isFailed }) => {
  // Styles
  const classes = useStyles();
  return (
    <Grid item className={classes.stepper}>
      <MuiThemeProvider theme={muiTheme}>
        {
          <Stepper
            alternativeLabel
            className={classes.stepperMob}
            activeStep={activeStep}
            connector={isFailed ? <ColorFailedConnector /> : <ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  classes={{ label: isFailed ? classes.failedLabel : '' }}
                  data-test={label}
                  StepIconComponent={props => (
                    <Icons isDelivery={isDelivery} isFailed={isFailed} {...props} />
                  )}
                  error={isFailed && index === steps.length - 1}
                >
                  {label.toUpperCase()}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        }
      </MuiThemeProvider>
    </Grid>
  );
};

export default TracingStepper;
