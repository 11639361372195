/** API endpoints directory */
export const API_PATH = {
  TRACE: '/trace',
  ORGANIZATION: '/organization',
  TRACK: '/track',
  FEEDBACK: '/feedback',
  DISAGREE: '/disagree',
  ADDRESS: '/change-address',
  DATE: '/change-date',
  MOBILE: '/mobile-url',
};

export const PUSH_API_PATHS = {
  SUBSCRIBE: '/subscriptions',
};

export const GOOGLE_API_PATHS = {
  GEOCODE: '/geocode/json',
};
