import { Grid, IconButton, Modal, Typography, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { useStyles } from './styles';
import React, { useState, useContext } from 'react';
import Context from '../../context';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrderData, useOrganization, useValidParams } from '../../hooks';
import { Redirect } from 'react-router-dom';
import ScheduleForm from '../../components/ScheduleForm';
import { Formik } from 'formik';
import rescheduleSchema from '../../schemas/reschedule';
import EmotionModal from '../../components/EmotionModal';
import SimpleModal from '../../components/SimpleModal';
import useRescheduleConfig from '../../hooks/useRescheduleConfig';
import Backdrop from '@material-ui/core/Backdrop';
import Api from '../../services/api';
import { snakeToCamelCase } from '../../utils';

const Reschedule = () => {
  const classes = useStyles();
  const context = useContext(Context);
  useOrganization(context);
  const { isOrderDataLoading } = useOrderData(context);
  const { qtn, organizationKey, orderData, organization } = context.state;
  const {
    data: rescheduleConfigData,
    isLoading: isRescheduleLoading,
    error,
  } = useRescheduleConfig(context.state);
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [form, setForm] = useState();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const { t } = useTranslation();
  let history = useHistory();
  const { goBack } = useHistory();
  const { paramsValid: validOrganizationKey } = useValidParams({ organizationKey });
  const { paramsValid: validQtn } = useValidParams({ qtn });

  function validateDate(day, maxDay, nonWorkDays, weekDays) {
    const FORMAT = 'YYYY-MM-DD';
    const currentDay = moment(day, FORMAT);
    const lastDay = moment(maxDay, FORMAT);
    const nextDay = currentDay.add(1, 'days').format(FORMAT);

    if (currentDay.isSameOrBefore(lastDay)) {
      if (nonWorkDays.includes(day)) {
        return validateDate(nextDay, maxDay, nonWorkDays, weekDays);
      } else {
        if (weekDays[currentDay.isoWeekday() - 1]) {
          return currentDay;
        } else {
          return validateDate(nextDay, maxDay, nonWorkDays, weekDays);
        }
      }
    }
    return null;
  }

  const initialValues = {
    deliveryDate: rescheduleConfigData
      ? validateDate(
          rescheduleConfigData.minRescheduleTime,
          rescheduleConfigData.maxRescheduleTime,
          rescheduleConfigData.nonWorkDays,
          rescheduleConfigData.weekDays,
        )
      : null,
    timeZone: 0,
  };
  const hasValidOrganizationKey = organizationKey ? validOrganizationKey : !!organizationKey;
  const hasValidQtn = qtn ? validQtn : !!qtn;
  if (!hasValidOrganizationKey) {
    return <Redirect to={'/missing-client-key'} />;
  }
  if (!hasValidQtn) {
    return <Redirect to={`/order-not-found?organization=${organizationKey}&qtn=${qtn}`} />;
  }
  if (orderData && organization) {
    if (!organization?.ctaConfig[snakeToCamelCase(orderData.status)]?.canRescheduleDate) {
      return <Redirect to={`/trace?qtn=${qtn}&organization=${organizationKey}`} />;
    }
  }

  const handleSubmit = values => {
    setForm(values);
    setModal(true);
  };

  const closeModal = () => {
    setSuccessModal(false);
    setModal(false);
    setOpenErrorDialog(false);
  };

  const handleConfirm = async () => {
    try {
      const body = {
        qtn,
        organizationKey,
        date: moment(form.deliveryDate).format('YYYY-MM-DD'),
        timeframe:
          rescheduleConfigData.timeframes.length > 0
            ? {
                from: rescheduleConfigData.timeframes[form.timeZone].from,
                to: rescheduleConfigData.timeframes[form.timeZone].to,
              }
            : null,
      };

      const response = await Api.postRescheduleConfig(body);
      if (response.data.success) {
        setModal(false);
        setSuccessModal(true);
      } else {
        setOpenErrorDialog(true);
      }
    } catch (e) {
      setOpenErrorDialog(e, true);
    }
  };

  const handleSuccess = () => {
    setSuccessModal(false);
    history.push(`/trace?qtn=${qtn}&organization=${organizationKey}`);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isRescheduleLoading || isOrderDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && error.response?.status !== 404 && (
        <Typography color={'error'} align={'center'} fontSize={'25px'}>
          {t('error.tryAgain')}
        </Typography>
      )}
      {rescheduleConfigData && !isRescheduleLoading && (
        <Grid container direction="column" justifyContent="center" className={classes.container}>
          <div style={{ display: 'flex' }}>
            <IconButton onClick={goBack} aria-label="delete" className={classes.backBtn}>
              <ArrowBackIcon />
            </IconButton>
            <Typography
              data-testid="changeAddressTitle"
              align={'left'}
              variant="h5"
              color="textPrimary"
              className={classes.title}
            >
              {t('orderState.texts.reschedule')}
            </Typography>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={rescheduleSchema}
            onSubmit={handleSubmit}
            validateOnMount={true}
          >
            {props => <ScheduleForm {...props} config={rescheduleConfigData} />}
          </Formik>
          <Modal
            className={classes.modal}
            open={modal}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <SimpleModal
              title={t('address.modal.confirmReschedule.title')}
              description={t('address.modal.confirmReschedule.subtitle')}
              onCancel={closeModal}
              onAgree={handleConfirm}
            />
          </Modal>
          <Modal
            className={classes.modal}
            open={successModal}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <EmotionModal
              title={t('address.modal.modalReschedule.success')}
              description={t('address.modal.modalReschedule.messageBody')}
              onClose={closeModal}
              onAgree={handleSuccess}
            />
          </Modal>
          {openErrorDialog && (
            <Modal
              className={classes.modal}
              open={openErrorDialog}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <EmotionModal
                title={t('error.error')}
                description={t('error.tryAgain')}
                onAgree={closeModal}
                icon="error"
              />
            </Modal>
          )}
        </Grid>
      )}
    </>
  );
};

export default Reschedule;
