import { useState, useEffect } from 'react';

const Marker = ({ map, options, onDrag }) => {
  const [marker, setMarker] = useState();
  useEffect(() => {
    if (!marker) {
      const newMarker = new window.google.maps.Marker({
        draggable: true,
      });
      setMarker(newMarker);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({ map, options });
      window.google.maps.event.addListener(marker, 'dragend', function (event) {
        if (onDrag) {
          onDrag({ lat: event.latLng.lat(), lng: event.latLng.lng() });
        }
      });
    }
  }, [marker, options, map, onDrag]);

  return null;
};

export default Marker;
