import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import TrackByQtn from '../../components/TrackByQtn';
import Context from '../../context';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useOrganization, useValidParams } from '../../hooks';
import TryAgainError from '../../components/TryAgainError';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Home() {
  // Styles
  const classes = useStyles();

  // Context
  const context = useContext(Context);
  const { qtn, organizationKey } = context.state;

  // Fetch organization data
  const { isLoading, error: errorLoadingOrg } = useOrganization(context);

  // Validate params
  const { paramsValid } = useValidParams({ organizationKey });

  // Semantic redirects
  const hasTraceInfo = qtn && organizationKey;
  const missingClientKey = !organizationKey;

  if (hasTraceInfo) return <Redirect to={`/trace?qtn=${qtn}&organization=${organizationKey}`} />;
  else if (missingClientKey || !paramsValid) return <Redirect to={'/missing-client-key'} />;
  else
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {errorLoadingOrg ? <TryAgainError /> : <TrackByQtn />}
      </>
    );
}
