import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    root: {
      top: '533px',
      left: '647px',
      width: '626px',
      height: 'max-content',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: '8px',
      opacity: 1,
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        width: 450,
      },
      [theme.breakpoints.down('xs')]: {
        width: 325,
      },
    },
    text: {
      margin: theme.spacing(1),
      width: '445px',
      boxSizing: 'borderBox',
    },
    contain: {
      background: '#F9F9F9 0% 0% no-repeat padding-box',
      border: '1px solid #D9E2EC',
      borderRadius: '4px',
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        width: '270px',
        height: '74px',
      },
    },
    button: {
      borderRadius: 29,
      width: 125,
      height: 50,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        width: '86px',
        height: '34px',
        fontSize: 12,
      },
    },
    cardAction: {
      justifyContent: 'center',
      padding: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    title2: {
      fontSize: 18,
      fontWeight: 600,
      padding: theme.spacing(2),
      textTransform: 'uppercase',
      color: '#000000',
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
        padding: theme.spacing(1),
      },
    },
    space2: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
    cardDel: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
        padding: theme.spacing(1),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
  { index: 1 },
);
