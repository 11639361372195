import { useEffect, useRef, Children, isValidElement, cloneElement, useState } from 'react';
import { useStyles } from './styles';

const Map = ({ center, zoom, children }) => {
  const classes = useStyles();
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      const mapInstance = new window.google.maps.Map(ref.current, {
        center,
        zoom,
      });
      setMap(mapInstance);
    }
    if (center.lat !== 0 && center.lng !== 0 && map) {
      map.setCenter(center);
    }
  }, [ref, map, center, zoom]);

  return (
    <>
      <div ref={ref} id="googleMap" className={classes.container} />
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;
