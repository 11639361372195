import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    title1: {
      fontSize: 27,
      fontWeight: 600,
      color: '#000000',
      opacity: 1,
      maxWidth: 625,
      letterSpacing: '0px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
        marginTop: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        maxWidth: 450,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 325,
        paddingBottom: theme.spacing(0),
      },
    },
    space: {
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0.5),
      },
    },
    iconFilled: {
      fill: '#1473f7 !important',
    },
    iconEmpty: {
      fill: '#cfd8dc',
    },
    iconActive: {
      fill: '#1473f7 !important',
    },
  }),
  { index: 1 },
);
