import { useState, useEffect, useCallback } from 'react';
import { SAAS_URL } from '../config';
import api from '../services/api';
import { isFullLink } from '../utils';

const useOrganization = context => {
  // Context
  const { organization, organizationKey, qtn } = context.state;
  const { setState } = context;

  //State
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const digestOrg = useCallback(organization => {
    let imgURL;
    const logoPath = organization.logo;
    if (logoPath) {
      imgURL = isFullLink(logoPath) ? logoPath : `${SAAS_URL}/img/${logoPath}`;
      organization.logo = imgURL;
    }
    organization.logo = imgURL;
    return organization;
  }, []);

  // Fetch organization data
  useEffect(() => {
    if (organization) return;
    if (!organizationKey) return;

    // Actual call
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const response = await api.fetchOrganization(qtn, organizationKey);
        const digestedOrg = digestOrg(response.data);
        setState(prev => ({ ...prev, organization: digestedOrg }));
        setIsLoading(false);
      } catch (networkError) {
        setIsLoading(false);
        setError(networkError);
      }
    };
    // Actual call
    fetchOrganization();
  }, [setState, organizationKey, qtn, organization, digestOrg]);

  return { error, isLoading };
};

export default useOrganization;
