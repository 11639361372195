import React, { useState, useEffect, createContext, useContext, useCallback, useRef } from 'react';
import { isFirefox } from 'react-device-detect';
import PropTypes from 'prop-types';

import { getToken, onMessage } from 'firebase/messaging';
import { VAPID } from '../config/firebaseConfig';
import { messaging } from '../init-fcm';
import PermissionPopover from '../components/PermissionPopover';
import PushApi from '../services/push-api';
import Context from '../context';

const FirebaseContext = createContext(undefined);

const FirebaseProvider = ({ children }) => {
  const didMount = useRef(null);
  const context = useContext(Context);
  const { qtn } = context.state;
  const initialPermissions = 'Notification' in window ? Notification.permission : 'denied';
  const [firebaseToken, setFirebaseToken] = useState(localStorage.getItem('firebaseToken'));
  const [showPopover, setShowPopover] = useState(isFirefox);
  const [permission, setPermission] = useState(initialPermissions);

  const requestPermissionToShowNotifications = useCallback(async () => {
    if ('Notification' in window) {
      const userPermission = await Notification.requestPermission();
      setPermission(userPermission);
      setShowPopover(false);
    }
  }, []);

  const getFirebaseRegistrationToken = useCallback(async messaging => {
    try {
      const token = await getToken(messaging, { vapidKey: VAPID });
      if (token) {
        localStorage.setItem('firebaseToken', token);
        setFirebaseToken(token);
      }
    } catch (err) {
      console.error('An error occurred while retrieving token. ', err);
    }
  }, []);

  const subscribeToPushService = useCallback(async (token, topic) => {
    try {
      await PushApi.postSubscribe(PushApi.buildSubscribeBody(token, topic));
    } catch (err) {
      console.error(`An error occurred while subscribing to topic ${topic}`, err);
    }
  }, []);

  const handleMessagesAppActive = useCallback(messaging => {
    onMessage(messaging, async payload => {
      if ('serviceWorker' in navigator) {
        const serviceWorkerPath = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
        const registration = await navigator.serviceWorker.getRegistration(serviceWorkerPath);
        registration.showNotification(payload.notification.title, payload.notification);
      }
    });
  }, []);

  // Request notification permissions
  useEffect(() => {
    if (!isFirefox && permission !== 'granted') {
      requestPermissionToShowNotifications();
    }
  }, [permission, requestPermissionToShowNotifications]);

  // Get registration token from message service once upon mount
  useEffect(() => {
    if (!messaging) return;
    if (permission === 'granted' && !didMount.current) {
      getFirebaseRegistrationToken(messaging);
      didMount.current = true;
    }
  }, [getFirebaseRegistrationToken, permission]);

  // Initializes onMessage push handler
  useEffect(() => {
    if (!firebaseToken) return;
    if (!messaging) return;
    handleMessagesAppActive(messaging);
  }, [handleMessagesAppActive, firebaseToken]);

  // Post to push-notifications api and subscribes to topic when available.
  useEffect(() => {
    if (firebaseToken && qtn) {
      subscribeToPushService(firebaseToken, qtn);
    }
  }, [subscribeToPushService, firebaseToken, qtn]);

  // Context value is composed by both state and mutator
  const value = { firebaseToken };

  return (
    <FirebaseContext.Provider value={value}>
      {showPopover && (
        <PermissionPopover
          handlePermission={requestPermissionToShowNotifications}
          handleClose={() => setShowPopover(false)}
        />
      )}
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseProvider;
