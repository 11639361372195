import * as Yup from 'yup';

const addressSchema = Yup.object().shape({
  address: Yup.string()
    .min(8, 'address.form.errors.address')
    .required('address.form.errors.required'),
  alternativeAddress: Yup.string().optional(),
  comments: Yup.string().optional().max(180, 'address.form.errors.tooLong'),
  phone: Yup.string()
    .matches(/^[0-9]*$/, 'address.form.errors.format')
    .min(8, 'address.form.errors.format')
    .required('address.form.errors.required'),
});

export default addressSchema;
