import moment from 'moment';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import 'moment/locale/en-au';
import 'moment/locale/es';

import { useStyles } from './styles';
import i18n from '../../config/i18n';

const DATE_FORMAT = 'YYYY-MM-DD';

const InputCalendarDatePicker = ({
  minDayLimit = '',
  maxDayLimit = '',
  nonWorkingDays = [],
  availableWeekDays = [],
  label = '',
  className = '',
  value,
  handleChange,
}) => {
  moment.locale(i18n.language);
  const classes = useStyles();

  const shouldDisableDate = day => {
    const minLimit = moment(minDayLimit, DATE_FORMAT);
    const maxLimit = moment(maxDayLimit, DATE_FORMAT);
    const isBefore = minDayLimit ? day.isBefore(minLimit) : false;
    const isAfter = day.isAfter(maxLimit);
    const isHolyday = nonWorkingDays.length
      ? nonWorkingDays.includes(day.format(DATE_FORMAT))
      : false;
    const dayOfWeek = day.day() - 1 < 0 ? 6 : day.day() - 1;
    const isNotAvailable = availableWeekDays.length ? !availableWeekDays[dayOfWeek] : false;

    return isBefore || isAfter || isHolyday || isNotAvailable;
  };
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        className={className}
        label={label}
        inputVariant="outlined"
        inputProps={{
          className: classes.input,
          'data-testid': 'inputDatePicker',
        }}
        renderDay={(day, selected, dayInCurrentMonth, dayComponent) => {
          return React.cloneElement(dayComponent, { 'data-testid': day.format('YYYY-MM-DD') });
        }}
        InputProps={{
          endAdornment: <CalendarTodayIcon />,
        }}
        format="DD/MM/YYYY"
        value={value}
        animateYearScrolling
        onChange={handleChange}
        shouldDisableDate={shouldDisableDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default InputCalendarDatePicker;
