import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const DialogTitle = props => {
  const { children, classes, handleClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h5"> {children}</Typography>
        </Box>
        <Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </MuiDialogTitle>
  );
};

const CustomDialog = props => {
  const { handleClose, open, title, content, actions } = props;
  const classes = useStyles();
  return (
    <div className={classes.dialogContainer}>
      <Dialog onClose={handleClose} open={open} data-testid="openDialog" fullWidth>
        <DialogTitle handleClose={handleClose}>{title}</DialogTitle>
        <MuiDialogContent className={classes.dialogContent}>{content}</MuiDialogContent>
        <hr />
        <MuiDialogActions>{actions}</MuiDialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
