import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
// Responsive assets
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ReactComponent as AddressIcon } from '../assets/address.svg';

const useBodyStyles = makeStyles(theme => ({
  root: {
    width: 350,
    minHeight: 165,
    maxHeight: 190,
    position: 'fixed',
    zIndex: '10',
    boxShadow: '0px 3px 6px #00000029',
    margin: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 24,
    marginLeft: 15,
  },
  pos: {
    marginBottom: 12,
  },
  orgAvatar: {
    height: 60,
    width: 60,
    border: '1px solid #ABBDCF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarImg: {
    height: 50,
    width: 50,
  },
  addressInfo: {
    marginTop: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  addressIcon: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  addressText: {
    fontSize: 16,
    fontWeight: 500,
    textOverflow: 'ellipsis',
  },
}));

const useResponsiveStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    zIndex: '10',
    position: 'absolute',
    bottom: 0,
  },
  summary: {
    margin: theme.spacing(0.75, 1.5),
  },
}));

const CardParent = ({ header, details }) => {
  const classes = useBodyStyles();
  return (
    <Card data-test="live-tracking-track-info" className={classes.root}>
      <CardContent>
        {header}
        {details}
      </CardContent>
    </Card>
  );
};

const AccordionParent = ({ header, details }) => {
  const classes = useResponsiveStyles();
  return (
    <div data-test="live-tracking-track-info" className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {header}
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default function TrackInfo({ data: { orderData, organization }, responsive }) {
  const classes = useBodyStyles();
  const { name, logo } = organization;
  const {
    delivery: { address },
  } = orderData;

  const addressToRender =
    address.split(',').length > 2 ? address.split(',').splice(0, 2).join(',') : address;

  const trackInfoHeader = (
    <Grid container>
      <Paper className={classes.orgAvatar}>
        <Avatar className={classes.avatarImg} alt={name} src={logo} />
      </Paper>
      <Typography className={classes.title} gutterBottom data-test="track-info-header">
        {name}
      </Typography>
    </Grid>
  );

  const trackInfoDetails = (
    <Grid container className={classes.addressInfo} alignItems="center" alignContent="center">
      <Grid className={classes.addressIcon} item>
        <Icon>
          <AddressIcon />
        </Icon>
      </Grid>
      <Typography className={classes.addressText} gutterBottom data-test="track-info-details">
        {addressToRender}
      </Typography>
    </Grid>
  );

  if (responsive) return <AccordionParent header={trackInfoHeader} details={trackInfoDetails} />;
  else return <CardParent header={trackInfoHeader} details={trackInfoDetails} />;
}
