import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { theme } from './config/theme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './index.css';

// I18n config
import './config/i18n';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* Suspense to wait for translations */}
    <Suspense
      fallback={
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    >
      <App />
    </Suspense>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
