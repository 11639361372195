import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { ReactComponent as FooterImage } from '../assets/footer.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Context from '../context';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import version from '../../package.json';
import { isDevelopment, sanitizeOnlyNumbers } from '../utils';

// Icons
import iconMail from './mail.png';
import whatsAppIcon from './whatsapp.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
  },
  main: {
    marginTop: theme.spacing(5),
    // Responsive
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  avatarLarge: {
    fontSize: 20,
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  orgLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    marginTop: 'auto',
    position: 'relative',
  },
  footerGround: {
    height: ({ isHome }) => (isHome ? '20vh' : '4vh'),
    width: '100vw',
    background:
      'transparent linear-gradient(180deg, #EBEBEB 0%, #F6F6F6 100%) 0% 0% no-repeat padding-box',
  },
  footerResponsive: {
    height: 'auto',
    bottom: ({ isHome }) => (isHome ? '20vh' : '4vh'),
    width: '100%',
  },
  appVersion: {
    height: '100%',
    paddingRight: theme.spacing(1),
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    padding: '0 24px',
    width: '100%',
    maxWidth: 530,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  contactRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  contactItem: {
    display: 'flex',
    marginRight: 16,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  contactIcon: {
    marginRight: '15px',
    objectFit: 'contain',
    width: '20px',
  },
}));

export default function Layout({ children }) {
  const [loadingLogo, setLoadingLogo] = useState(true);
  const location = useLocation();
  const classes = useStyles({ isHome: location.pathname === '/' });
  const context = useContext(Context);
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));
  const hasLogo = useMemo(
    () => Boolean(context.state.organization?.logo),
    [context.state.organization],
  );
  const { t } = useTranslation();
  const handleLogoLoad = () => setLoadingLogo(false);

  return (
    <div className={classes.root} data-test="layout">
      {/* Content */}
      <main className={classes.main}>
        {hasLogo && (
          <Container className={classes.orgLogo} maxWidth="sm">
            {loadingLogo && <CircularProgress />}
            <img
              alt="logo"
              width={responsive ? 120 : 175}
              src={context.state.organization.logo}
              style={{ display: loadingLogo ? 'none' : 'block' }}
              onLoad={handleLogoLoad}
            />
          </Container>
        )}
        {children && (
          <Container className={classes.content} maxWidth="md" disableGutters>
            <Grid>{children}</Grid>
          </Container>
        )}
      </main>
      {/* End Content */}
      {/* Footer */}
      <footer className={classes.footer}>
        {context.state.organization && context.state.organization?.contact?.email && (
          <Grid item className={classes.contactContainer}>
            <Typography color="textPrimary" align="center">
              {t('footer.title')}
            </Typography>
            <div className={classes.contactRow}>
              {context.state.organization?.contact?.email && (
                <a
                  href={`mailto:${context.state.organization?.contact?.email}`}
                  className={classes.contactItem}
                >
                  <img className={classes.contactIcon} src={iconMail} alt="mail-icon" />
                  <Typography color="textPrimary" align="left">
                    {context.state.organization.contact.email}
                  </Typography>
                </a>
              )}
              {context.state.organization?.contact?.phone && (
                <a
                  href={`https://wa.me/${sanitizeOnlyNumbers(
                    context.state.organization.contact.phone,
                  )}`}
                  className={classes.contactItem}
                >
                  <img className={classes.contactIcon} src={whatsAppIcon} alt="whatsapp-icon" />
                  <Typography color="textPrimary" align="left">
                    {context.state.organization.contact.phone}
                  </Typography>
                </a>
              )}
            </div>
          </Grid>
        )}
        <FooterImage className={responsive ? classes.footerResponsive : {}} />
        <Box className={classes.footerGround}>
          {isDevelopment && (
            <Grid
              className={classes.appVersion}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  V{version.version}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </footer>
      {/* End footer */}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};
