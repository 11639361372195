import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    width: '100%',
    fontSize: 32,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  subtitleContainer: {
    width: '100%',
    marginTop: 13,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subtitle: {
    fontSize: '12px',
    lineHeight: '26px',
    marginRight: '5px',
    color: '#607D8B',
    paddingLeft: 10,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    },
    [theme.breakpoints.up('xs')]: {
      paddingTop: 0,
    },
  },
  subtitleBold: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '26px',
    paddingLeft: 10,
  },
  backgroundItem: {
    background: '#E3EEFE',
    margin: '5px',
    borderRadius: '4px',
    maxWidth: '100%',
    minWidth: '170px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '296px',
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '296px',
      display: 'flex',
    },
  },
}));
