import { memo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles } from './styles';

const InputOutlinedLabeled = props => {
  const classes = useStyles();
  return (
    <FormControl>
      {props.label && (
        <InputLabel shrink htmlFor={props.field.name}>
          {props.label}
        </InputLabel>
      )}
      <InputBase
        classes={classes}
        type={props.type}
        {...props.field}
        multiline={props.multiline}
        inputProps={{
          'data-testid': 'inputOutlined',
        }}
      />
    </FormControl>
  );
};

export default memo(InputOutlinedLabeled);
