import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(5),
    fontWeight: 500,
    fontSize: 32,
    width: 364,
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    borderRadius: 29,
    width: 125,
    height: 50,
    fontSize: 16,
  },
}));
