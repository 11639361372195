import { makeStyles } from '@material-ui/core/styles';
import { green, yellow, red } from '@material-ui/core/colors';

const icon = {
  height: 50,
  width: 50,
  marginBottom: 16,
};
export const useStyles = makeStyles(theme => ({
  container: {
    width: 435,
    minHeight: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconSuccess: { ...icon, color: green[500] },
  iconWarning: { ...icon, color: yellow[500] },
  iconError: { ...icon, color: red[500] },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: { alignSelf: 'flex-end', cursor: 'pointer' },
  title: { marginBottom: 16 },
  actions: { justifyContent: 'center', marginBottom: 18 },
  cancel: { padding: '6px 35px' },
  ok: { padding: '6px 35px', borderRadius: 16 },
}));
