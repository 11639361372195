import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useStyles } from './styles';

const TracingOperationHeader = ({
  title,
  isDelivery,
  isDelivered,
  orderLabel,
  organization,
  qtn,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Typography
        className={classes.title}
        component="h3"
        variant="h3"
        align="center"
        color="textPrimary"
        data-test="title"
      >
        {title}
      </Typography>
      {isDelivery && !isDelivered && (
        <Grid container className={classes.subtitleContainer} direction="row">
          <Grid item md={4}>
            <div className={classes.backgroundItem}>
              <Typography
                className={classes.subtitle}
                component="h2"
                variant="subtitle1"
                color="textPrimary"
              >
                {t('orderState.texts.order')}:
              </Typography>
              <Typography
                className={classes.subtitleBold}
                component="h2"
                variant="subtitle1"
                color="textPrimary"
                data-test="orderid"
              >
                {orderLabel}
              </Typography>
            </div>
          </Grid>
          {organization && (
            <>
              <Grid item md={4}>
                <div className={classes.backgroundItem}>
                  <Typography
                    className={classes.subtitle}
                    component="h2"
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {t('orderState.texts.merchant')}:
                  </Typography>
                  <Typography className={classes.subtitleBold} component="h2" color="textPrimary">
                    {organization}
                  </Typography>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className={classes.backgroundItem}>
                  <Typography
                    className={classes.subtitle}
                    component="h2"
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {t('schemas.qtn.qtn')}:
                  </Typography>
                  <Typography className={classes.subtitleBold} component="h2" color="textPrimary">
                    #{qtn}
                  </Typography>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

export default TracingOperationHeader;
