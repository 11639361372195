import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column-reverse',
      marginBottom: 20,
    },
    helper: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      marginTop: 4,
    },
    label: {
      color: 'rgba(0, 0, 0, 0.54)',
      padding: 0,
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1,
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
      marginBottom: 8,
      '&:focus': {
        color: '#1473F7',
      },
    },
    input: {
      border: '1px solid #ced4da',
      padding: '10px 12px',
      position: 'relative',
      fontSize: 16,
      transition:
        'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: 4,
      backgroundColor: '#fff',

      '&:hover': {
        borderColor: '#000 !important',
      },
      '&:focus': {
        border: '2px solid #1473F7',
      },

      '&:focus + label': {
        color: '#1473F7',
      },
      '&:focus-visible': {
        outline: 'none',
      },
    },
  };
});
