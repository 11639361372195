import { axiosPushApi as axios } from '../http';
import { axiosResolver } from '../../utils';
import { PUSH_API_PATHS } from '../../config/api-paths';
import { CONSUMER_API_KEY, PROJECT_ID } from '../../config';

const options = {
  headers: {
    'x-consumer-apikey': CONSUMER_API_KEY,
  },
};
class PushApi {
  static postSubscribe(body) {
    return axiosResolver(() => axios.post(PUSH_API_PATHS.SUBSCRIBE, body, options));
  }

  static buildSubscribeBody(token, qtn) {
    return {
      projectId: PROJECT_ID,
      registrationToken: token,
      topic: qtn,
      platform: 'WEB',
    };
  }
}

export default PushApi;
