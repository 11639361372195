import { useState, useEffect, useMemo, useCallback } from 'react';
import InputAutocomplete from '../InputAutocomplete';
import GoogleMap from '../GoogleMap';
import { useStyles } from './styles';

const REVERSE_FILTER = 'street_address';

const LocationInput = props => {
  const classes = useStyles();
  const { name, setFieldValue, initialValues } = props;
  const [options, setOptions] = useState([]);
  const [hasToGeoCode, setHasToGeoCode] = useState(true);
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const [address, setAddress] = useState(initialValues);
  const [markers, setMarkers] = useState([]);
  const geocoder = useMemo(() => new window.google.maps.Geocoder(), []);
  const autocomplete = useMemo(() => new window.google.maps.places.AutocompleteService(), []);
  const handleUpdateOptions = input => {
    autocomplete.getPlacePredictions({ input }, (response, status) => {
      if (status === 'OK') {
        setOptions(response.map(item => item.description));
      } else {
        setOptions(['Sin coincidencias']);
      }
    });
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    const hasSelectedAutoComplete = options.includes(newValue);
    props.onChange(event);
    if (hasSelectedAutoComplete) {
      setHasToGeoCode(true);
      setAddress(newValue);
    }
  };

  const handleDrag = useCallback(
    location => {
      setHasToGeoCode(false);
      geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK') {
          const nearestStreets = results.filter(result => result.types.includes(REVERSE_FILTER));
          if (nearestStreets.length) setFieldValue(name, nearestStreets[0].formatted_address);
        } else {
          console.log(status);
        }
      });
    },
    [geocoder, name, setFieldValue],
  );

  const buildMarker = useCallback(
    location => ({
      options: {
        position: location,
      },
      onDrag: handleDrag,
    }),
    [handleDrag],
  );

  useEffect(() => {
    if (hasToGeoCode && address) {
      geocoder.geocode({ address }, (result, status) => {
        if (status === 'OK') {
          const location = {
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng(),
          };
          setPosition(location);
          setMarkers([buildMarker(location)]);
          setFieldValue('coordinates', `${String(location.lat)},${location.lng}`);
          setHasToGeoCode(false);
          setAddress(address);
        } else {
          console.log(status);
        }
      });
      setHasToGeoCode(false);
    }
  }, [geocoder, hasToGeoCode, buildMarker, setFieldValue, initialValues, setAddress, address]);

  return (
    <div className={classes.container}>
      <div
        data-testid="coordinates"
        data-lat={markers.length ? String(markers[0].options.position.lat) : '0'}
        data-lng={markers.length ? String(markers[0].options.position.lng) : '0'}
        style={{ display: 'none' }}
      />
      <InputAutocomplete
        {...props}
        handleUpdateOptions={handleUpdateOptions}
        onChange={handleInputChange}
        options={options}
      />
      <GoogleMap center={position} zoom="15" markers={markers} />
    </div>
  );
};

export default LocationInput;
