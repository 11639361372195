import { FormControl, Select } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

const OutlinedSelect = ({ children, field, form }) => {
  const { name, value } = field;
  const { setFieldValue } = form;
  const classes = useStyles();

  return (
    <FormControl variant="outlined">
      <Select
        name={name}
        value={value}
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
        className={classes.input}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default OutlinedSelect;
