export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const IS_TESTING = ENVIRONMENT === 'test';
export const API_URL = process.env.REACT_APP_API_URL;
export const SAAS_URL = process.env.REACT_APP_SAAS_URL;
export const HERE_APP_ID = process.env.REACT_APP_HERE_APP_ID;
export const HERE_APP_CODE = process.env.REACT_APP_HERE_APP_CODE;
export const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY;
export const TRACK_INTERVAL = process.env.REACT_APP_TRACK_INTERVAL;
export const ORDER_INTERVAL = process.env.REACT_APP_ORDER_INTERVAL;
export const PUSH_API_URL = process.env.REACT_APP_PUSH_API_URL;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const CONSUMER_API_KEY = process.env.REACT_APP_CONSUMER_API_KEY;
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || process.env.REACT_APP_GOOGLE_MAPS_APIKEY;
export const GOOGLE_MAPS_URL =
  process.env.REACT_APP_GOOGLE_MAPS_URL || 'https://maps.googleapis.com/maps/api';
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
export const GTM_TRACKING_ID = process.env.REACT_APP_GTM_TRACKING_ID;
