import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { ReactComponent as BadIcon } from '../../assets/icon_feedback_malo.svg';
import { ReactComponent as RegularIcon } from '../../assets/icon_feedback_regular.svg';
import { ReactComponent as GoodIcon } from '../../assets/icon_feedback_bueno.svg';
import { ReactComponent as MuyBueno } from '../../assets/icon_feedback_muybueno.svg';
import { ReactComponent as ExcellentIcon } from '../../assets/icon_feedback_excelente.svg';
import { useStyles } from './Styles';

const customIcons = className => ({
  1: {
    icon: <BadIcon className={className} />,
  },
  2: {
    icon: <RegularIcon className={className} />,
  },
  3: {
    icon: <GoodIcon className={className} />,
  },
  4: {
    icon: <MuyBueno className={className} />,
  },
  5: {
    icon: <ExcellentIcon className={className} />,
  },
});

const Emoticon = React.memo(function (props) {
  const { value, active, ...other } = props;
  const classes = useStyles();
  const className = clsx(classes.littleFace, { [classes.iconActive]: value === active });
  return (
    <div {...other} data-test={`overall-satisfaction-${value}`} data-testid={`emoticon-${value}`}>
      {customIcons(className)[value].icon}
    </div>
  );
});

Emoticon.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Emoticon;
