import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Layouts
import Layout from '../layout/Layout';
// Routes
import Feedback from './Feedback/Feedback';
import OrderState from './OrderState';
import Home from './Home';
import LiveTracking from './LiveTracking';
import Address from './Address';
//Errors
import MissingClientKey from './errors/MissingClientKey';
import NotFoundByQtn from './errors/NotFoundByQtn';
import NotFound from './errors/NotFound';
// Hooks
import { useGaTracker, useGtmTracker } from '../hooks';
import Reschedule from './Reschedule';

function Routes() {
  // Google Analytics
  useGaTracker();
  // Google Tag Manager
  useGtmTracker();
  return (
    <Switch>
      <Route exact path="/live-tracking">
        <LiveTracking />
      </Route>
      <Route>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/trace">
              <OrderState />
            </Route>
            <Route exact path="/feedback">
              <Feedback />
            </Route>
            <Route exact path="/missing-client-key">
              <MissingClientKey />
            </Route>
            <Route exact path="/order-not-found">
              <NotFoundByQtn />
            </Route>
            <Route exact path="/address">
              <Address />
            </Route>
            <Route exact path="/reschedule">
              <Reschedule />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
}

export default Routes;
