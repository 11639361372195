import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_TRACKING_ID } from '../config';
import { DEV_HOSTS } from '../config/enums';

// Initializes Google Tag manager
const useGtmTracker = () => {
  // Only initialize google analytics on deployed environments
  useEffect(() => {
    if (!Object.values(DEV_HOSTS).some(host => window.location.href.includes(host))) return;
    TagManager.initialize([
      {
        gtmId: GTM_TRACKING_ID,
      },
    ]);
  }, []);
};

export default useGtmTracker;
