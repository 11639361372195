import axios from 'axios';
import { API_URL, IS_TESTING, PUSH_API_URL } from '../../config';

import { AxiosMockInstance } from './mock_adapters';

// Configure axios instance
const defaultOptions = {
  baseURL: API_URL,
};

// Create instance
const axiosApiInstance = axios.create(defaultOptions);

// Configure axios instance
const defaultPushOptions = {
  baseURL: PUSH_API_URL,
};

// Create instance
const axiosPushApiInstance = axios.create(defaultPushOptions);

// Exports
export const axiosApi = IS_TESTING ? AxiosMockInstance : axiosApiInstance; // Export real production instance or mock one
export const axiosPushApi = axiosPushApiInstance;
