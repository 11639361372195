import { qtnSchema, clientKeySchema } from '../schemas';

/**
 * Wraps axios call and returns actual data at succeed or handles error
 * @param {axiosFn} func
 */
export const axiosResolver = async func => {
  try {
    const response = await func();
    return response.data;
  } catch (err) {
    console.error('Request Failed:', err.config);
    if (err.response) {
      console.error('Status:', err.response.status);
      console.error('Data:', err.response.data);
      console.error('Headers:', err.response.headers);
    } else {
      console.error('Error Message:', err.message);
    }
    throw err;
  }
};

/**
 * Simple constant that determines if environment is not at production level
 */
export const isDevelopment =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_ENVIRONMENT === 'development';

/**
 * Validates if provided qtn or organization key are valid params
 * @param {qtn} string of numbers
 * @param {organizationKey} string organization uuid
 * @return bool
 */
export const validateParams = async ({ qtn, organizationKey }) => {
  const qtnIsValid = qtn ? await qtnSchema.isValid(qtn) : true;
  const orgKeyIsValid = organizationKey ? await clientKeySchema.isValid(organizationKey) : true;

  return qtnIsValid && orgKeyIsValid;
};

/**
 *
 * @param {string} str
 */
export const snakeToCamelCase = str =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));

/**
 * Checks if strings starts with http or https.
 * Used to know if url is partial or not.
 * @param {url} string
 * @return bool
 */
export const isFullLink = url => {
  return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
};

export const rand = (maxLimit = 100) => {
  let rand = Math.random() * maxLimit;
  return Math.floor(rand);
};

export const sanitizeOnlyNumbers = value => (value ? value.replace(/[^0-9]+/g, '') : '');
