import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 626,
    display: 'flex',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    opacity: 1,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      width: 450,
    },
    [theme.breakpoints.down('xs')]: {
      width: 327,
      maxHeight: 600,
      boxShadow: 'none',
      borderTop: '1px solid  rgba(207,216,220, 1)',
      borderRadius: 0,
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    textAlign: 'left',
    opacity: 1,
    color: 'rgba(96, 125, 139, 1)',
    fontSize: 16,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  pos: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    marginBottom: 12,
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      textAlign: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: 0,
      marginBottom: 18,
    },
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    fontWeight: 700,
  },
  posBold: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    marginBottom: 12,
    fontWeight: 600,
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '20px',
      color: 'black',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      textAlign: 'left',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: 0,
      marginBottom: 18,
    },
  },
  cardTitle: {
    textAlign: 'left',
    fontSize: ({ isFeedback, isResponsive }) => (isFeedback || isResponsive ? 22 : 24),
    fontWeight: 600,
    color: 'black',
    opacity: 1,
    marginTop: theme.spacing(1),
  },
  cardBody: {
    width: 626,
    [theme.breakpoints.down('sm')]: {
      width: 450,
    },
    padding: theme.spacing(3, 3),
  },
  cardSubtitle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgba(96, 125, 139, 1)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  tableContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    padding: 15,
    maxHeight: 150,
    maxWidth: 578,
    marginTop: '10px',
    backgroundColor: '#E3EEFE',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
      maxHeight: 'inherit',
      padding: '10px 5px',
    },
  },
  failedContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableCellText: {
    lineHeight: '16px',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    margin: 0,
  },
  buttons: {
    borderRadius: '16px',
    margin: 10,
  },
  closeButton: {
    display: 'flex',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    padding: '0 24px',
    width: '100%',
    height: '100%',
    maxWidth: 530,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  contactRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  contactItem: {
    display: 'flex',
    marginRight: 16,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  contactIcon: {
    marginRight: '15px',
    objectFit: 'contain',
    width: '20px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
    marginTop: 'auto',
    position: 'relative',
  },
  canDisagreeButton: {
    borderRadius: '16px',
    textTransform: 'none',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 600,
  },
  actionsContainer: {
    width: '100%',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
