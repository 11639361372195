import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#FFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 8,
    display: 'flex',
    height: '150px',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
    width: '625px',
    [theme.breakpoints.down('md')]: {
      height: '250px',
      maxWidth: 625,
    },
    //Mobile
    [theme.breakpoints.down('xs')]: {
      height: '180px',
      maxWidth: 300,
    },
    //Cypress 1000px
    [theme.breakpoints.between('lg')]: {
      height: '250px',
      maxWidth: 625,
    },
  },
  left: {
    flex: '1 1 445px',
    display: 'flex',
    maxWidth: 445,
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 290px',
      maxWidth: 290,
      position: 'relative',
    },
  },
  leftTitles: {
    backgroundColor: '#1473F7',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 265px',
    padding: '16px 0 16px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0 16px 16px',
      flex: '1 1 210px',
      maxWidth: 210,
    },
  },
  titles: {
    fontFamily: 'sans-serif',
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '26px',
    color: '#FFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '18px',
      position: 'absolute',
      zIndex: 10,
    },
  },
  leftCircle: {
    backgroundColor: '#1473F7',
    flex: '1 1 82px',
    minWidth: 82,
    clipPath: 'circle(70% at 0 50%)',
    [theme.breakpoints.down('md')]: {
      minWidth: 300,
      clipPath: 'circle(95% at 0 65%)',
    },
    [theme.breakpoints.down('xs')]: {
      clipPath: 'circle(95% at 0 65%)',
      minWidth: 155,
    },
  },
  right: {
    flex: '1 1 147px',
    maxWidth: 147,
    position: 'relative',
  },
  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '160px',
    [theme.breakpoints.down('xs')]: {
      width: '130px',
    },
  },
  links: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },
  },
  linkBtn: {
    marginRight: 5,
  },
}));
