import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';
import { useStyles } from './styles';
import NotFoundImg from '../../assets/errors/not-found-error.png';

export default function NotFoundByQtn() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
      <Grid item className={classes.img}>
        <img alt="not-found-error" src={NotFoundImg} />
      </Grid>
      <Grid item>
        <Typography color="primary" align="center" variant="h2">
          404
        </Typography>
        <Typography align="center" className={classes.title} variant="h6">
          {t('missingClientKey.title')}
        </Typography>
      </Grid>
    </Grid>
  );
}
