import React, { useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next/';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { qtnSchema } from '../../schemas';
import { FormHelperText, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Context from '../../context';
import { useStyles } from './styles';

const validationSchema = Yup.object({
  qtn: qtnSchema,
});

export default function QtnSearchInput() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const context = useContext(Context);

  //Responsive design
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleError = useCallback(() => {
    setHasError(!hasError);
    // Hides after some time
    setTimeout(() => {
      setHasError(prev => !prev);
    }, 5000);
  }, [hasError]);

  const handleSubmit = async ({ qtn }) => {
    try {
      await qtnSchema.isValid(qtn);
      context.setState(prev => ({ ...prev, qtn }));
      history.push(`/trace?qtn=${qtn}&organization=${context.state.organizationKey}`);
    } catch (error) {
      handleError();
      console.error('Error while validating qtn: ', error);
    }
  };

  return (
    <Formik initialValues={{ qtn: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, values, handleBlur, handleChange, touched, isValid, errors }) => (
        <Form>
          {hasError ? (
            <Typography align="center" color="error">
              {t('error.tryAgain')}
            </Typography>
          ) : (
            <Paper className={classes.root}>
              <SearchIcon className={classes.iconButton} fontSize="large" />
              <InputBase
                name="qtn"
                onBlur={handleBlur}
                className={classes.inputBase}
                placeholder={
                  matches ? t('trackByQtn.responsive.placeholder') : t('trackByQtn.placeholder')
                }
                onChange={handleChange}
                value={values.qtn}
                classes={{ input: classes.input }}
                data-test="track-by-qtn-input"
              />
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                className={classes.button}
                variant="contained"
                color="primary"
                data-test="track-by-qtn-button"
                id="track-by-qtn-button"
              >
                {t('trackByQtn.button')}
              </Button>
            </Paper>
          )}
          {errors.qtn && touched.qtn && (
            <FormHelperText
              className={classes.helperText}
              error
              data-test="track-by-qtn-form-helper"
            >
              {t(`${errors.qtn}`)}
            </FormHelperText>
          )}
        </Form>
      )}
    </Formik>
  );
}
