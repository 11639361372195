import { useState, useEffect, useCallback, useRef } from 'react';
import api from '../services/api';

/**
 * @param {Context} context
 * @param {Number} interval
 * @returns {Object} {isLoading, error}
 */

const useOrderData = (context, interval = null) => {
  // Context
  const { qtn, organizationKey, orderData } = context.state;
  const { setState } = context;

  // Mounting reference
  const didMount = useRef(null);
  //State
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const traceOrder = useCallback(async () => {
    try {
      // Only show loading state once
      if (!didMount.current) setIsLoading(true);
      const response = await api.traceOrder(qtn, organizationKey);
      setState(prev => ({ ...prev, orderData: response.data }));
      setIsLoading(false);
    } catch (networkError) {
      setIsLoading(false);
      setError(networkError);
    }
  }, [organizationKey, qtn, setState]);

  // Fetch order data
  useEffect(() => {
    if (orderData) return;
    if (!organizationKey) return;
    if (!qtn) return;
    // Fires data fetch at mount for first time
    if (!didMount.current) {
      // Actual call
      traceOrder();
      didMount.current = true;
    }
  }, [organizationKey, qtn, orderData, traceOrder]);

  // Fetch order data every x interval in milliseconds
  useEffect(() => {
    if (!interval) return;
    const trackTraceOrder = setInterval(() => {
      traceOrder();
    }, interval);
    return () => clearInterval(trackTraceOrder);
  }, [traceOrder, interval]);

  return { error, isLoading };
};

export default useOrderData;
