import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';

import { ReactComponent as StarEmpty } from '../../assets/star_empty.svg';
import { useStyles } from './styles';

const StartRateBar = ({ name, label, rate, setRate }) => {
  const classes = useStyles();
  return (
    <Grid data-testid="start-container" className={classes.direction}>
      <Typography
        data-testid={`question-${name.replace(/\s+/g, '-').toLowerCase()}`}
        className={classes.punt}
      >
        {label}
      </Typography>
      <Rating
        className={classes.rating}
        classes={{
          icon: classes.spaceStars,
          iconFilled: classes.iconFilled,
          iconEmpty: classes.starsEmpty,
        }}
        name={name}
        data-test={name}
        precision={1}
        icon={<StarEmpty />}
        value={rate}
        onChange={setRate}
      />
    </Grid>
  );
};

export default StartRateBar;
