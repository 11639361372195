import PropTypes from 'prop-types';

import StartRateBar from '../../components/StartRateBar';

const FeedbackRateQuestions = ({ questions }) => {
  const handleStartRateSelect = stateHandler => {
    return (event, newValue) => {
      if (newValue != null) stateHandler(newValue);
    };
  };
  return (
    <>
      {questions.map(question => (
        <StartRateBar
          key={question.name}
          name={question.name}
          label={question.label}
          rate={question.rate}
          setRate={handleStartRateSelect(question.setRate)}
        />
      ))}
    </>
  );
};

FeedbackRateQuestions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      rate: PropTypes.number,
      setRate: PropTypes.func,
    }),
  ),
};

export default FeedbackRateQuestions;
