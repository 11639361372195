import { useState, useEffect, useCallback, useRef } from 'react';
import api from '../services/api';

const useRescheduleConfig = context => {
  const { qtn, organizationKey } = context;
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const didMount = useRef(null);
  const rescheduleConfig = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.rescheduleConfig(qtn, organizationKey);
      const getConfig = response.data;
      setData(getConfig);
      setIsLoading(false);
    } catch (networkError) {
      setError(networkError);
      setIsLoading(false);
    }
  }, [organizationKey, qtn]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      rescheduleConfig();
    }
  }, [rescheduleConfig]);
  return {
    error,
    isLoading,
    data,
  };
};

export default useRescheduleConfig;
