import { axiosApi as axios } from '../http';
import { axiosResolver } from '../../utils';
import { API_PATH } from '../../config/api-paths';

const options = {};
class Api {
  static fetchOrganization(qtn, organizationKey) {
    return axiosResolver(() =>
      axios.get(API_PATH.ORGANIZATION, { ...options, params: { qtn, organizationKey } }),
    );
  }
  static traceOrder(qtn, organizationKey) {
    return axiosResolver(() =>
      axios.get(API_PATH.TRACE, { ...options, params: { qtn, organizationKey } }),
    );
  }
  static postFeedback(body) {
    return axiosResolver(() => axios.post(API_PATH.FEEDBACK, body));
  }
  static postDisagreement(body) {
    return axiosResolver(() => axios.post(API_PATH.DISAGREE, body));
  }
  static updateAddressSchedule({ qtn, organizationKey, address }) {
    return axiosResolver(() =>
      axios.get(API_PATH.ADDRESS, { ...options, params: { qtn, organizationKey, address } }),
    );
  }
  static updateAddress({ qtn, organizationKey, address, alternativeAddress, comments, phone }) {
    return axiosResolver(() =>
      axios.post(API_PATH.ADDRESS, {
        qtn,
        organizationKey,
        address,
        alternativeAddress,
        comments,
        phone,
      }),
    );
  }
  static rescheduleConfig(qtn, organizationKey) {
    return axiosResolver(() =>
      axios.get(API_PATH.DATE, { ...options, params: { qtn, organizationKey } }),
    );
  }

  static postRescheduleConfig({ qtn, organizationKey, date, timeframe }) {
    return axiosResolver(() =>
      axios.post(API_PATH.DATE, {
        qtn,
        organizationKey,
        date,
        timeframe,
      }),
    );
  }

  static fetchMobileUrlPath(qtn, organizationKey, requestUrl) {
    return axiosResolver(() =>
      axios.get(API_PATH.MOBILE, { ...options, params: { qtn, organizationKey, requestUrl } }),
    );
  }
}

export default Api;
