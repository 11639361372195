import React from 'react';
import { useTranslation } from 'react-i18next/';
import Typography from '@material-ui/core/Typography';

export default function TryAgainError() {
  const { t } = useTranslation();
  return (
    <Typography data-test="error" align="center" color="error">
      {t('error.tryAgain')}
    </Typography>
  );
}
