export const firebaseConfig = {
  apiKey: 'AIzaSyAbHjA7-unAEuceIKLVl8B0ptN0KvUDMGs',
  authDomain: 'track-and-trace-push.firebaseapp.com',
  projectId: 'track-and-trace-push',
  storageBucket: 'track-and-trace-push.appspot.com',
  messagingSenderId: '392141027725',
  appId: '1:392141027725:web:9341f84ef97117925f65c4',
  measurementId: 'G-LK160NXSBY',
};
export const VAPID =
  'BHmkPFCprKy5Qwh54J5pJhfpUedglLEMRW700XmkOUOpF2TaBRasOvbdQPMsWosa3XJBAocy1641yzydANZzEr4';
