import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';
import { makeStyles } from '@material-ui/core/styles';
import QtnSearchInput from './QtnSearchInput';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(5),
    fontWeight: 500,
    fontSize: 32,
    // Responsive
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
    },
  },
}));

export default function TrackByQtn() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
      data-test="track-by-qtn"
    >
      <Typography
        align="center"
        className={classes.title}
        variant="h5"
        data-test="track-by-qtn-title"
      >
        {t('trackByQtn.title')}
      </Typography>
      <QtnSearchInput />
    </Grid>
  );
}
