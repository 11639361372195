import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  theme => ({
    direction: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    punt: {
      textAlign: 'left',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 15,
      color: '#000000',
      opacity: 1,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        color: '#000000',
      },
    },
    rating: {
      display: 'flex',
      justifyContent: 'flex-end',
      bottom: 15,
    },
    spaceStars: {
      padding: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        width: 25,
        height: 25,
      },
    },
    iconFilled: {
      fill: '#1473f7 !important',
    },
    iconEmpty: {
      fill: '#cfd8dc',
    },
    starsEmpty: {
      stroke: '#1473f7',
      fill: 'none',
      [theme.breakpoints.down('sm')]: {
        border: '2px',
      },
    },
  }),
  { index: 1 },
);
