import { useState, useEffect } from 'react';
import { validateParams } from '../utils';

/**
 * Validates params through schemas and returns state
 * @param {object} params list of params to evaluate as keys from object
 */
const useValidParams = params => {
  // State
  const [paramsValid, setParamsValid] = useState(true);
  const [validating, setValidating] = useState(true);

  // Validating params
  useEffect(() => {
    const promise = async () => {
      const valid = await validateParams(params);
      setParamsValid(valid);
      setValidating(false);
    };
    promise();
  }, [params]);
  return { paramsValid, validating };
};

export default useValidParams;
