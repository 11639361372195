import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import Emoticon from '../Emoticon';
import { useStyles } from './styles';

/*************
 *  @param {indexIconSelected} 1: bad 2: regular 3: good 4: very good 5: excellent
 *  @param {handleSelectIcon} handle option selected
 */

const EmoticonRateBar = ({ label, indexIconSelected, handleSelectIcon }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction="column" alignItems="center" data-testid="emoticonBar">
      <Typography data-test="questions-general-feedback" className={classes.title1} align="center">
        {label || t('feedBack.value')}
      </Typography>
      <Grid className={classes.space}>
        <Rating
          classes={{
            iconEmpty: classes.iconEmpty,
            iconFilled: classes.iconEmpty,
            iconActive: classes.iconFilled,
          }}
          name="overall-satisfaction"
          data-test="overall-satisfaction"
          precision={1}
          value={indexIconSelected}
          IconContainerComponent={props => <Emoticon active={indexIconSelected} {...props} />}
          onChange={handleSelectIcon}
        />
      </Grid>
    </Grid>
  );
};

export default EmoticonRateBar;
