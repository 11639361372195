import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const SimpleModal = ({ title, description, onCancel, onAgree, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CardContent>
            <Typography color="textPrimary" variant="subtitle1">
              {title}
            </Typography>
            <Typography color="textSecondary">{description}</Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            {onCancel && (
              <Button onClick={onCancel} size="small">
                {t('address.modal.cancel')}
              </Button>
            )}
            <Button
              onClick={onAgree}
              size="small"
              color="primary"
              data-testid="firstConfirmationButton"
            >
              {t('address.modal.ok')}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default SimpleModal;
