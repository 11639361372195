export const MAP_CONFIG = {
  zoom: 17,
};

export const MAP_UI_CONTROLS = {
  zoomSettings: {
    position: 'top-right',
  },
  layersSettings: {
    disabled: true,
    visibility: false,
  },
};

export const MAP_TYPE = 'normal.map';

export const MAP_THEME = 'reduced.day';

export const MAP_STYLES = {
  height: '100vh',
  width: '100vw',
};
