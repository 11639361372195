import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  return {
    container: {
      width: '100%',
      minHeight: 160,
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        height: 330,
      },
    },
  };
});
