import React from 'react';
import { useStyles } from './styles';
import ios from '../../assets/btn_appstore.png';
import android from '../../assets/btn_google.png';
import bannerImage from '../../assets/banner-image.png';
import { useTranslation } from 'react-i18next/';

const MobileBanner = ({ link }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div data-test="mobile-app-banner" className={classes.container}>
      <div className={classes.left}>
        <div className={classes.leftTitles}>
          <span className={classes.titles}>{t('orderState.texts.mobileBanner')}</span>
          <div className={classes.links}>
            <a
              data-test="ios-mobile-download-btn"
              href={link}
              className={classes.linkBtn}
              id="iosDownload"
            >
              <img src={ios} alt="ios" />
            </a>
            <a
              data-test="android-mobile-download-btn"
              href={link}
              className={classes.linkBtn}
              id="playStoreDownload"
            >
              <img src={android} alt="android" />
            </a>
          </div>
        </div>
        <div className={classes.leftCircle}></div>
      </div>
      <div className={classes.right}>
        <img className={classes.image} src={bannerImage} alt="banner" />
      </div>
    </div>
  );
};

export default MobileBanner;
