import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { Typography, Grid, Modal, CircularProgress, Backdrop } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { GOOGLE_MAPS_API_KEY } from '../../config/index';
import EmotionModal from '../../components/EmotionModal';
import AddressForm from '../../components/AddressForm';
import SimpleModal from '../../components/SimpleModal';
import Context from '../../context';
import { useStyles } from './styles';
import { useOrderData, useOrganization, useValidParams } from '../../hooks';
import api from '../../services/api';
import addressSchema from '../../schemas/address';
import { snakeToCamelCase } from '../../utils';

const MODAL_INITIAL = {
  open: false,
  confirm: true,
  success: false,
  warning: false,
  error: false,
  loading: false,
  info: '',
};

const Address = () => {
  //Styles
  const classes = useStyles();

  // Fetch data
  const context = useContext(Context);
  const { isLoading } = useOrderData(context);
  const { setState } = context;
  useOrganization(context);
  const { qtn, organizationKey, orderData, organization } = context.state;

  //Validate base data
  const { paramsValid: validOrganizationKey } = useValidParams({ organizationKey });
  const { paramsValid: validQtn } = useValidParams({ qtn });
  const hasValidOrganizationKey = organizationKey ? validOrganizationKey : !!organizationKey;
  const hasValidQtn = qtn ? validQtn : !!qtn;
  // Translate
  const { t } = useTranslation();

  // Variables
  const [modal, setModal] = useState(MODAL_INITIAL);
  const [form, setForm] = useState();
  const { goBack } = useHistory();
  let history = useHistory();

  const initialValues = {
    address: orderData?.delivery?.address || '',
    alternativeAddress: '',
    comments: '',
    phone: '',
  };

  const render = status => {
    if (status === Status.FAILURE) return <div>Error</div>;
    return <CircularProgress />;
  };

  const buildUpdateAddressBody = (qtn, organizationKey, addressForm) => {
    const { alternativeAddress, comments, phone, address, coordinates } = addressForm;
    const [lat, lng] = coordinates.split(',');
    return {
      qtn,
      organizationKey,
      alternativeAddress,
      comments,
      phone,
      address: {
        fullAddress: address,
        lat,
        lng,
      },
    };
  };

  const handleConfirm = async () => {
    try {
      setModal(prevState => ({ ...prevState, loading: true }));
      const responseSchedule = await api.updateAddressSchedule({
        qtn,
        organizationKey,
        address: form.coordinates,
      });
      if (responseSchedule.data.hasToReschedule) {
        setModal(prevState => ({
          ...prevState,
          warning: true,
          confirm: false,
          loading: false,
          info: 'address.modal.warning.subtitle',
        }));
      } else {
        const responseUpdate = await api.updateAddress(
          buildUpdateAddressBody(qtn, organizationKey, form),
        );
        setModal(prevState => ({
          ...prevState,
          success: true,
          confirm: false,
          loading: false,
          info: 'address.modal.success.subtitle',
        }));
        setState(prevState => ({ ...prevState, orderData: responseUpdate.data }));
      }
    } catch (err) {
      setModal(prevState => ({
        ...prevState,
        error: true,
        warning: false,
        loading: false,
        info: 'address.modal.error.subtitle',
      }));
    }
  };

  const closeModal = () => setModal(MODAL_INITIAL);

  const handleSubmit = values => {
    setModal(prevState => ({ ...prevState, open: true }));
    setForm(values);
  };

  const handleSuccess = () => {
    setModal(MODAL_INITIAL);
    history.push(`/trace?qtn=${qtn}&organization=${organizationKey}`);
  };

  const handleReschedule = async () => {
    try {
      setModal(prevState => ({ ...prevState, loading: true }));
      const response = await api.updateAddress(buildUpdateAddressBody(qtn, organizationKey, form));
      setModal(prevState => ({
        ...prevState,
        success: true,
        warning: false,
        loading: false,
        info: 'address.modal.success.subtitle2',
      }));
      setState(prevState => ({ ...prevState, orderData: response.order }));
    } catch (err) {
      setModal(prevState => ({
        ...prevState,
        error: true,
        warning: false,
        loading: false,
        info: 'address.modal.error.subtitle',
      }));
    }
  };

  if (!hasValidOrganizationKey) {
    return <Redirect to={'/missing-client-key'} />;
  }
  if (!hasValidQtn) {
    return <Redirect to={`/order-not-found?organization=${organizationKey}&qtn=${qtn}`} />;
  }
  if (orderData && organization) {
    if (!organization?.ctaConfig[snakeToCamelCase(orderData.status)]?.canChangeAddress) {
      return <Redirect to={`/trace?qtn=${qtn}&organization=${organizationKey}`} />;
    }
  }

  const closeModalSuccess = () => {
    setModal(MODAL_INITIAL);
    goBack();
  };

  return (
    <Wrapper apiKey={GOOGLE_MAPS_API_KEY} render={render} libraries={['places']}>
      {isLoading && (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container direction="column" justifyContent="center" className={classes.container}>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={goBack} aria-label="delete" className={classes.backBtn}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            data-testid="changeAddressTitle"
            align={'left'}
            variant="h5"
            color="textPrimary"
            className={classes.title}
          >
            {t('address.backButton')}
          </Typography>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={addressSchema}
          component={AddressForm}
          onSubmit={handleSubmit}
          validateOnMount={true}
        />
        <Modal
          className={classes.modal}
          open={modal.open && modal.confirm}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <SimpleModal
            title={t('address.modal.confirm.title')}
            description={t('address.modal.confirm.subtitle')}
            onCancel={closeModal}
            onAgree={handleConfirm}
            isLoading={modal.loading}
          />
        </Modal>
        <Modal
          className={classes.modal}
          open={modal.open && modal.success}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <EmotionModal
            title={t('address.modal.success.title')}
            description={t(modal.info)}
            onClose={closeModalSuccess}
            onAgree={handleSuccess}
            isLoading={modal.loading}
          />
        </Modal>
        <Modal
          className={classes.modal}
          open={modal.open && modal.warning}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <EmotionModal
            title={t('address.modal.warning.title')}
            description={t(modal.info)}
            onCancel={closeModal}
            onAgree={handleReschedule}
            icon="warning"
            isLoading={modal.loading}
          />
        </Modal>
        <Modal
          className={classes.modal}
          open={modal.open && modal.error}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <EmotionModal
            title={t('address.modal.error.title')}
            description={t(modal.info)}
            onCancel={closeModal}
            onAgree={closeModal}
            icon="error"
            isLoading={modal.loading}
          />
        </Modal>
      </Grid>
    </Wrapper>
  );
};

export default Address;
