import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './styles';
import i18next from '../../config/i18n';
import { OPERATIONS, STATES } from '../../config/enums';
import CustomDialog from '../OpenDialog/OpenDialog';
import TableDetails from '../TableDetails/TableDetails';
import iconMail from '../../layout/mail.png';
import whatsAppIcon from '../../layout/whatsapp.png';
import { sanitizeOnlyNumbers, snakeToCamelCase } from '../../utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import api from '../../services/api/api';
import { useHistory } from 'react-router-dom';
import EmotionModal from '../EmotionModal';
import SimpleModal from '../SimpleModal';
import { default as img_empty_state } from '../../assets/img_empty_state.svg';
import { default as reschedule_icon } from '../../assets/reschedule-icon.svg';
import { default as warning_icon } from '../../assets/warning-icon.svg';
import { default as address_icon } from '../../assets/address-icon.svg';

const getSemanticDate = date => {
  const localeOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  const sematicDate = Intl.DateTimeFormat(i18next.language, localeOptions).formatToParts(date);

  const getValueByKey = key => sematicDate.find(part => part.type === key)?.value;

  const weekday = getValueByKey('weekday');
  const day = getValueByKey('day');
  const month = getValueByKey('month');

  return { weekday, day, month };
};

export default function OrderInfoCard({
  orderData,
  isFeedback,
  organization,
  qtn,
  organizationKey,
}) {
  // Responsive design
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  // Style
  const classes = useStyles({
    isFeedback,
    isResponsive: matches,
  });
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [disagreeError, setDisagreeError] = useState(false);

  //Translate
  const { t } = useTranslation();

  // Boolean helpers
  const isDelivered = orderData.status === STATES.DELIVERED;
  const isPickup = orderData.operation === OPERATIONS.PICKUP;
  const isFailed = orderData.failed && Object.keys(orderData).includes('fulfilledState');

  // Full date strings
  const fullEstimatedDate = orderData.delivery.date;
  const fullFulfilledDate = orderData.fulfilled?.fullDate;

  let dateToRender = '';

  // Estimated/Fulfilled date with weekday and month names
  const dateToFormat = isDelivered ? fullFulfilledDate : fullEstimatedDate;
  const [date] = dateToFormat.split(' ');
  const [year, month, day] = date.split('-');
  const resolvedDate = new Date(year, month - 1, day);

  const semanticDate = getSemanticDate(resolvedDate);

  dateToRender = `${semanticDate.weekday} ${semanticDate.day} ${t('card.of')} ${
    semanticDate.month
  }`;

  // Estimated delivery time frame message
  let estimatedTime;
  const { estimatedArrival } = orderData;
  const etaDisabled = orderData.etaDisabled;
  const etaNotAvailable = estimatedArrival === '' || estimatedArrival === null;
  const etaFailed = typeof estimatedArrival === 'string' && estimatedArrival.length;

  // config  according state
  const DISABLED_ALL_MODIFICATIONS = {
    canReschedule: false,
    canChangeAddress: false,
    canDisagree: false,
    reschedule: {
      successTitle: '',
      successMessage: '',
    },
  };
  const orderStateConfig = orderData.failed
    ? DISABLED_ALL_MODIFICATIONS
    : organization.ctaConfig[snakeToCamelCase(orderData.status)] || DISABLED_ALL_MODIFICATIONS;

  if (etaDisabled || etaNotAvailable) estimatedTime = '';
  else if (etaFailed) {
    estimatedTime = `${t('card.defaultEstimatedTime')}  pm`;
  } else {
    const { from, to } = estimatedArrival;
    estimatedTime = `${t('card.from')} ${from} ${t('card.to')} ${to}`;
  }

  // Fulfilled/will be fulfilled message
  const fulfilledMessage = isPickup ? 'card.fulfilled' : 'card.delivered';
  const willBeFulfilled = isPickup ? 'card.willBeFulfilled' : 'card.willArrive';
  const failedReason = isFailed
    ? orderData.fulfilledState.detailedState.description ||
      orderData.fulfilledState.overallState.description
    : '';
  const failedDateFormat = {
    dateStyle: 'full',
    timeStyle: 'short',
    hour12: true,
  };
  const dateIsDefined = Boolean(orderData.fulfilledState?.date);
  const failedDate = isFailed
    ? new Intl.DateTimeFormat(i18next.language, failedDateFormat).format(
        new Date(orderData.fulfilledState.date),
      )
    : '';
  let cardTextBody;
  if (isDelivered)
    cardTextBody = `${t(fulfilledMessage)} ${t('card.on')} ${dateToRender} ${t('card.at')} ${
      orderData.fulfilled.time
    }`;
  else cardTextBody = `${t(willBeFulfilled)} ${t('card.on')} ${dateToRender} ${estimatedTime}`;
  const items = orderData.items || [];
  const createData = (name, qty) => {
    return { name, qty };
  };
  const detailsRows = items.map(item => createData(item.name, item.qty));

  const handleDisagreeClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCloseSucessDialog = useCallback(() => {
    setOpenSuccessDialog(false);
  }, []);

  const handleCanDisagree = useCallback(async () => {
    try {
      const payload = {
        qtn,
        organizationKey,
      };
      const response = await api.postDisagreement(payload);

      if (response.data.success) {
        setOpen(false);
        setOpenSuccessDialog(true);
      } else {
        setDisagreeError(true);
        setOpen(false);
      }
    } catch (error) {
      setDisagreeError(true);
      setOpen(false);
    }
  }, [qtn, organizationKey]);

  const handleClickReschedule = useCallback(() => {
    if (organization.ctaConfig[snakeToCamelCase(orderData.status)].canRescheduleDate === true) {
      history.push(`/reschedule?qtn=${qtn}&organization=${organizationKey}`);
    } else {
      setModal(true);
    }
  }, [history, organization, orderData, organizationKey, qtn]);

  const handleConfirm = async () => {
    try {
      const body = {
        qtn,
        organizationKey,
      };
      const response = await api.postRescheduleConfig(body);
      if (response.data.success) {
        setModal(false);
        setSuccessModal(true);
      } else {
        setOpenErrorDialog(true);
      }
    } catch (e) {
      setOpenErrorDialog(e, true);
    }
  };

  const closeModal = () => {
    setModal(false);
    setSuccessModal(false);
    setOpenErrorDialog(false);
  };

  const handleSuccess = () => {
    setSuccessModal(false);
  };

  return (
    <Card className={classes.root}>
      <Grid className={classes.cardBody} container>
        {isFailed ? (
          <Grid container className={classes.failedContainer}>
            <Grid>
              <Typography className={classes.cardSubtitle} variant="body1" color="textSecondary">
                {t('card.reason')}:
              </Typography>
              <Typography
                data-test="card-text-reason"
                className={classes.posBold}
                color="textSecondary"
                align="left"
              >
                {failedReason}
              </Typography>
            </Grid>
            {dateIsDefined && (
              <Grid>
                <Typography className={classes.cardSubtitle} variant="body1" color="textSecondary">
                  {t('card.date')}:
                </Typography>
                <Typography
                  data-test="card-text-date"
                  className={classes.pos}
                  color="textSecondary"
                  align="left"
                >
                  {failedDate}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid item style={{ width: '100%' }}>
            <Typography className={classes.title} variant="body1" color="textSecondary">
              {t('card.title')}:
            </Typography>
            <Typography
              data-testid="card-text-body"
              className={classes.cardTitle}
              variant="h5"
              component="h5"
              align="left"
            >
              {cardTextBody}
            </Typography>
            {orderStateConfig.canReschedule && (
              <Button
                id="reschedule-button"
                data-testid="reschedule"
                startIcon={
                  <img src={reschedule_icon} alt="" style={{ width: '16px', height: '16px' }} />
                }
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: '20px',
                  cursor: 'pointer',
                  marginBottom: 30,
                  marginTop: 12,
                  border: '2px solid',
                  fontSize: '14px',
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                onClick={handleClickReschedule}
              >
                {t('orderState.texts.reschedule')}
              </Button>
            )}
            <Dialog
              className={classes.modal}
              open={modal}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <SimpleModal
                title={t('address.modal.confirmReschedule.title')}
                description={t('address.modal.confirmReschedule.subtitle')}
                onCancel={closeModal}
                onAgree={handleConfirm}
              />
            </Dialog>
            <Dialog
              className={classes.modal}
              open={successModal}
              onClose={closeModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <EmotionModal
                title={
                  orderStateConfig.reschedule.successTitle || t('orderState.texts.gratitudeOrder')
                }
                description={
                  orderStateConfig.reschedule.successMessage ||
                  t('orderState.texts.orderWontDelivered')
                }
                onCancel={closeModal}
                onAgree={handleSuccess}
              />
            </Dialog>
            {openErrorDialog && (
              <Dialog
                className={classes.modal}
                open={openErrorDialog}
                onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <EmotionModal
                  title={t('error.error')}
                  description={t('error.tryAgain')}
                  onAgree={closeModal}
                  icon="error"
                />
              </Dialog>
            )}
          </Grid>
        )}
        <Grid item style={{ marginTop: isFailed ? 0 : 15 }}>
          <Typography className={classes.cardSubtitle} variant="body1" color="textSecondary">
            {t('card.deliveryAddress')}
          </Typography>
          <Typography className={classes.pos} color="textSecondary" align="left">
            {orderData.delivery.address}
          </Typography>
          {orderStateConfig.canChangeAddress && (
            <Link
              to={`/address?qtn=${qtn}&organization=${organizationKey}`}
              style={{ textDecoration: 'none' }}
            >
              <Button
                id="changeAddress-button"
                data-testid="changeAddress"
                startIcon={
                  <img src={address_icon} alt="" style={{ width: '16px', height: '16px' }} />
                }
                variant="outlined"
                color="primary"
                style={{
                  borderRadius: '20px',
                  cursor: 'pointer',
                  marginBottom: 30,
                  textTransform: 'none',
                  border: '2px solid',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {t('orderState.texts.changeAddress')}
              </Button>
            </Link>
          )}
        </Grid>
        {detailsRows.length > 0 ? (
          <>
            <Grid item style={{ width: '100%' }}>
              <Typography className={classes.cardSubtitle} variant="body1" color="textSecondary">
                {t('card.orderDetails')}
              </Typography>
              <TableDetails data={detailsRows} />
            </Grid>
            <Grid item style={{ width: '100%', marginTop: 15 }}>
              {orderStateConfig.canDisagree && (
                <Button
                  id="isNotWhatIWanted-button"
                  style={{
                    borderRadius: '20px',
                    cursor: 'pointer',
                    marginBottom: 30,
                    textTransform: 'none',
                    border: '2px solid',
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                  variant="outlined"
                  color="secondary"
                  onClick={handleDisagreeClick}
                  startIcon={
                    <img src={warning_icon} style={{ width: '16px', height: '16px' }} alt="" />
                  }
                  data-testid="canDisagreeButton"
                >
                  {t('orderState.texts.canDisagree')}
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <>
            {orderStateConfig.canDisagree && (
              <>
                <Grid item style={{ width: '100%', marginTop: 15 }}>
                  <Typography
                    className={classes.cardSubtitle}
                    variant="body1"
                    color="textSecondary"
                  >
                    {t('card.cancelOrder')}
                  </Typography>
                  <Button
                    id="isNotWhatIWanted-button"
                    style={{
                      borderRadius: '20px',
                      cursor: 'pointer',
                      marginBottom: 30,
                      textTransform: 'none',
                      border: '2px solid',
                      fontSize: '14px',
                      fontWeight: 600,
                      marginTop: 15,
                    }}
                    variant="outlined"
                    color="secondary"
                    onClick={handleDisagreeClick}
                    startIcon={
                      <img src={warning_icon} style={{ width: '16px', height: '16px' }} alt="" />
                    }
                    data-testid="cancelDisagreeButton"
                  >
                    {t('address.form.cancel')}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title={t(detailsRows.length > 0 ? 'card.orderDetails' : 'card.dontRecognizeThisOrder')}
        content={
          <>
            {detailsRows.length > 0 && <Typography>{t('card.orderProducts')}:</Typography>}
            {detailsRows.length > 0 ? (
              <TableDetails data={detailsRows} />
            ) : (
              <TableContainer
                className={classes.tableContainer}
                data-testid="emptyStateTableDetails"
              >
                <Table className={classes.table} size="small" aria-label="simple table">
                  <TableBody>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img src={img_empty_state} alt="" />
                    </div>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        }
        actions={
          <>
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                style={{ backgroundColor: '#FF333F', borderRadius: 16, padding: '6px 35px' }}
                color="secondary"
                className={classes.buttons}
                onClick={handleCanDisagree}
                data-test="cancelOrderButton"
              >
                {t('orderState.texts.cancelShipping')}
              </Button>
              <Button
                color="primary"
                className={classes.buttons}
                onClick={handleClose}
                fullWidth
                data-test="backButton"
              >
                {t('notFoundByQtn.button')}
              </Button>
            </Grid>
          </>
        }
      />
      <CustomDialog
        open={openSuccessDialog}
        handleClose={handleCloseSucessDialog}
        title={
          !disagreeError ? (
            <Grid container justifyContent="center" data-test="successDialog">
              <Grid style={{ width: '100%' }} container justifyContent="center" item>
                <div style={{ padding: 10 }}>
                  <Typography variant="h5">
                    {
                      organization.ctaConfig[snakeToCamelCase(orderData.status)]?.disagree
                        ?.successTitle
                    }
                  </Typography>
                </div>
              </Grid>
              <div
                style={{
                  padding: 10,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <Typography>
                  {
                    organization.ctaConfig[snakeToCamelCase(orderData.status)]?.disagree
                      ?.successMessage
                  }
                </Typography>
              </div>
            </Grid>
          ) : (
            <div style={{ display: 'flex' }} data-test="errorDialog">
              <Typography variant="h5" style={{ marginLeft: 10 }}>
                {t('error.error')}
              </Typography>
            </div>
          )
        }
        content={
          <>
            {!disagreeError ? (
              <Grid item>
                <Typography color="textPrimary" align="center">
                  {t('footer.title')}
                </Typography>
                <div className={classes.contactRow}>
                  {organization?.contact?.email && (
                    <a
                      href={`mailto:${organization?.contact?.email}`}
                      className={classes.contactItem}
                    >
                      <img className={classes.contactIcon} src={iconMail} alt="mail-icon" />
                      <Typography color="textPrimary" align="left">
                        {organization.contact.email}
                      </Typography>
                    </a>
                  )}
                  {organization?.contact?.phone && (
                    <a
                      href={`https://wa.me/${sanitizeOnlyNumbers(organization.contact.phone)}`}
                      className={classes.contactItem}
                    >
                      <img className={classes.contactIcon} src={whatsAppIcon} alt="whatsapp-icon" />
                      <Typography color="textPrimary" align="left">
                        {organization.contact.phone}
                      </Typography>
                    </a>
                  )}
                </div>
              </Grid>
            ) : (
              <Typography>{t('error.tryAgain')}</Typography>
            )}
          </>
        }
        actions={
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.buttons}
              onClick={handleCloseSucessDialog}
              fullWidth
              data-testid="acceptRescheduleButton"
            >
              {t('address.modal.ok')}
            </Button>
          </Grid>
        }
      />
    </Card>
  );
}
const { PREPARING, ASSIGNED, ROUTE_STARTED, ARRIVING, DELIVERED } = STATES;
const validateDeliveredProps = function (props, propName) {
  if (
    props['status'] === STATES.DELIVERED &&
    (props[propName] === undefined || typeof props[propName] !== 'string')
  ) {
    return new Error(
      `Please provide a valid ${propName}. Required prop Type String, but received ${typeof props[
        propName
      ]}.`,
    );
  }
};

OrderInfoCard.propTypes = {
  feedBack: PropTypes.bool,
  orderData: PropTypes.shape({
    status: PropTypes.oneOf([PREPARING, ASSIGNED, ROUTE_STARTED, ARRIVING, DELIVERED]).isRequired,
    delivery: PropTypes.shape({
      date: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
    }).isRequired,
    estimatedArrival: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ]),
    operation: PropTypes.string.isRequired,
    fulfilled: PropTypes.shape({
      time: validateDeliveredProps,
      date: validateDeliveredProps,
      fullDate: PropTypes.string,
    }),
  }).isRequired,
};

OrderInfoCard.defaultProps = {
  feedBack: false,
};
