import React from 'react';

//Images
import Preparing from '../assets/preparacion_activo.png';
import Assigned from '../assets/asignado_activo.png';
import PreparingFailed from '../assets/icon_preparacion_fail.svg';
import AssignedFailed from '../assets/icon_asignada_fail.svg';
import InTransitActive from '../assets/camino_activo.png';
import InTransitInactive from '../assets/camino_inactivo.png';
import ArrivingActive from '../assets/cerca_activo.png';
import ArrivingInactive from '../assets/cerca_inactivo.png';
import DeliveredActive from '../assets/entregado_activo.png';
import DeliveredInactive from '../assets/entregado_inactivo.png';
import DeliveredFailed from '../assets/icon_no_realizado_fail.svg';

//Changes
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
    width: 90,
    height: 90,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
  },
  completed: {
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
    boxShadow: '6px 8px 14px -3px rgba(0,0,0,0.79)',
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1,
      width: 60,
      height: 60,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  gridImg: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Icons(props) {
  //State
  const { active, completed, isDelivery, isFailed } = props;

  //Style
  const classes = useColorlibStepIconStyles();

  //Icons
  const icons = {
    1: isDelivery ? (isFailed ? PreparingFailed : Preparing) : isFailed ? AssignedFailed : Assigned,
    2: isFailed ? InTransitInactive : completed ? InTransitActive : InTransitInactive,
    3: isFailed ? ArrivingInactive : completed ? ArrivingActive : ArrivingInactive,
    4: isFailed ? DeliveredFailed : completed ? DeliveredActive : DeliveredInactive,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <Grid className={classes.gridImg}>
        <img className={classes.img} width="105" src={icons[String(props.icon)]} alt="icon" />
      </Grid>
    </div>
  );
}

Icons.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
