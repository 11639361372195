import { BrowserRouter as Router } from 'react-router-dom';
import ContextProvider from './providers';
import FirebaseProvider from './providers/firebaseProvider';
import Routes from './routes';

function App() {
  return (
    <Router>
      <ContextProvider fallback={<p>loading</p>}>
        <FirebaseProvider>
          <Routes></Routes>
        </FirebaseProvider>
      </ContextProvider>
    </Router>
  );
}

export default App;
