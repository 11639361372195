import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';
import { useStyles } from './styles';
import NotFoundImg from '../../assets/errors/order-not-found-error.png';
import { useHistory } from 'react-router-dom';
import Context from '../../context';

export default function NotFoundByQtn() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const context = useContext(Context);
  const { setState } = context;
  const { organizationKey } = context.state;

  const handleClick = () => {
    setState(prev => ({ ...prev, qtn: null, organizationKey: organizationKey }));
    history.push(`/?organization=${organizationKey}`);
  };

  return (
    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
      <Grid item className={classes.img}>
        <img width={112} height={112} alt="client-key-error" src={NotFoundImg} />
      </Grid>
      <Grid item>
        <Typography
          data-test="notFoundByQtnTitle"
          align="center"
          className={classes.title}
          variant="h5"
        >
          {t('notFoundByQtn.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={handleClick}
          className={classes.button}
          id="buttonNotFound"
          variant="contained"
          color="primary"
        >
          {t('notFoundByQtn.button')}
        </Button>
      </Grid>
    </Grid>
  );
}
