import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const PermissionPopover = ({ handlePermission, handleClose }) => {
  const classes = useStyles();
  //Translate
  const { t } = useTranslation();
  return (
    <Card data-testid="permission" className={classes.container} variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {t('permission.message')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="secondary" onClick={handleClose}>
          {t('permission.close')}
        </Button>
        <Button size="small" color="primary" onClick={handlePermission}>
          {t('permission.set')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default PermissionPopover;
