import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const EmotionModal = ({
  title,
  description,
  onCancel,
  onClose,
  onAgree,
  icon = 'success',
  isLoading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const ICONS = {
    success: <CheckCircleOutlineIcon className={classes.iconSuccess} />,
    warning: <WarningIcon className={classes.iconWarning} />,
    error: <ErrorOutlineIcon className={classes.iconError} />,
  };
  return (
    <Card className={classes.container} data-testid="emotionModal">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CardContent className={classes.content}>
            {<CloseIcon className={classes.close} onClick={onCancel || onClose} />}
            {ICONS[icon]}
            <Typography color="textPrimary" align="center" variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Typography color="textSecondary" align="center">
              {description}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            {onCancel && (
              <Button
                id={`${title.toLowerCase().replaceAll(' ', '-')}-cancel`}
                onClick={onCancel}
                size="small"
                color="textSecondary"
                className={classes.cancel}
              >
                {t('address.modal.cancel')}
              </Button>
            )}
            <Button
              id={`${title.toLowerCase().replaceAll(' ', '-')}-confirm`}
              variant="contained"
              onClick={onAgree}
              size="small"
              color="primary"
              className={classes.ok}
              data-testid="secondConfirmationButton"
            >
              {t('address.modal.ok')}
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default EmotionModal;
