import Map from './Map';
import Marker from './Marker';

const GoogleMap = ({ center, zoom = 4, markers }) => {
  return (
    <Map center={center} zoom={Number(zoom)}>
      {markers.map((marker, index) => (
        <Marker key={`marker-${index}`} options={marker.options} onDrag={marker.onDrag} />
      ))}
    </Map>
  );
};

export default GoogleMap;
