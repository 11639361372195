import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isDevelopment } from '../../utils';

import Context from '../../context';
import CustomModal from '../../components/Modal';
import { STATES } from '../../config/enums';
import { ORDER_INTERVAL } from '../../config';
import MobileBanner from '../../components/MobileBanner';
import TracingOperation from '../../components/TracingOperation';
import { useOrganization, useOrderData, useValidParams, fetchMobileUrlPath } from '../../hooks';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

//Stepper
const getStepByOrderStatus = ordStatus => {
  switch (ordStatus) {
    case STATES.PREPARING:
      return 1;
    case STATES.ROUTE_STARTED:
      return 2;
    case STATES.ARRIVING:
      return 3;
    case STATES.DELIVERED:
      return 4;
    default:
      return 1;
  }
};

export default function OrderState() {
  const [showModal, setShowModal] = useState(false);
  //Translate
  const { t } = useTranslation();

  const interval = ORDER_INTERVAL ?? 10000;

  // Styles
  const classes = useStyles();

  //Setup Context Hook
  const context = useContext(Context);
  // Fetch orderdata
  const { isLoading: isOrderLoading, error } = useOrderData(context, interval);
  // Fetch mobile Url path

  const { isLoading: isLoadingMobileUrl } = fetchMobileUrlPath(context);

  //Context Destructuring
  const { qtn, organizationKey, orderData, organization, mobileUrl } = context.state;

  // Order state step
  const step = useMemo(() => (orderData ? getStepByOrderStatus(orderData.status) : 0), [orderData]);

  // Validate params
  const { paramsValid, validating } = useValidParams({ organizationKey });

  // Semantics redirects
  const missingQtn = !qtn;
  const missingClientKey = !organizationKey;

  // Fetch organization data
  const { isLoading: isOrgLoading } = useOrganization(context);

  // If order is arriving renders live tracking map
  const isLiveTracking = useMemo(() => orderData?.status === STATES.ARRIVING, [orderData]);

  const webViewString = window.location.search;

  useEffect(() => {
    if (webViewString.includes('&wv=1')) return;
    const qtnLocal = localStorage.getItem('QTN');
    if (mobileUrl) {
      if (qtnLocal === null || qtn !== qtnLocal) {
        const timeToOpen = isDevelopment ? 1000 : 3000;
        const timer = setTimeout(() => {
          setShowModal(true);
          localStorage.setItem('QTN', qtn);
        }, timeToOpen);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [qtn, mobileUrl, webViewString]);

  const ref = useRef(null);

  if (missingQtn || !paramsValid || error?.response?.status === 404)
    return <Redirect to={`/order-not-found?organization=${organizationKey}&qtn=${qtn}`} />;
  else if (missingClientKey) return <Redirect to={'/missing-client-key'} />;
  else if (isLiveTracking && orderData?.enableLiveTrack)
    return <Redirect to={`/live-tracking?organization=${organizationKey}&qtn=${qtn}`} />;
  else {
    return (
      <>
        <CustomModal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          ref={ref}
        >
          <div>
            <MobileBanner link={mobileUrl} />
          </div>
        </CustomModal>

        <Backdrop
          className={classes.backdrop}
          open={isOrgLoading || isOrderLoading || validating || isLoadingMobileUrl}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {error && error.response?.status !== 404 && (
          <Typography color={'error'} align={'center'} fontSize={'25px'}>
            {t('error.tryAgain')}
          </Typography>
        )}
        {orderData?.status && organization && <TracingOperation step={step} />}
      </>
    );
  }
}
