import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { GA_TRACKING_ID } from '../config';
import { DEV_HOSTS } from '../config/enums';

// Initializes and then sets page tracking based on location
const useGaTracker = () => {
  const history = useHistory();
  // Only initialize google analytics on deployed environments
  useEffect(() => {
    if (!Object.values(DEV_HOSTS).some(host => window.location.href.includes(host))) return;
    ReactGA.initialize([
      {
        trackingId: GA_TRACKING_ID,
      },
    ]);
  }, []);

  // Subscribes to location changes and submits pageviews
  useEffect(() => {
    if (!ReactGA.isInitialized) return;

    // To track the first pageview upon load
    ReactGA.send(window.location.pathname + window.location.search);

    // To track the subsequent pageviews
    history.listen(location => {
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    });
  }, [history]);
};

export default useGaTracker;
