import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Grid, useTheme } from '@material-ui/core';
import Context from '../../context';
import OrderInfoCard from '../OrderInfoCard';
import { OPERATIONS, STATES } from '../../config/enums';
import { useStyles } from './styles';
import TracingStepper from '../TracingStepper';
import TracingOperationHeader from '../TracingOperationHeader';
import useWindowSize from '../../hooks/useWindowSize';
import { snakeToCamelCase } from '../../utils';

const TracingOperation = React.memo(function StepTracing({ step }) {
  // Context
  const context = useContext(Context);
  const theme = useTheme();
  const size = useWindowSize();
  const { qtn, organizationKey, orderData, organization } = context.state;

  // Styles
  const classes = useStyles();

  // Translate
  const { t } = useTranslation();

  // Boolean helper
  const isDelivery = orderData.operation === OPERATIONS.DELIVERY;
  const isFailed = orderData.failed;

  // Steps
  const steps = [
    isDelivery ? t('orderState.steps.preparing.label') : t('orderState.steps.assigned.label'),
    t('orderState.steps.routeStarted.label'),
    t('orderState.steps.arriving.label'),
    isFailed
      ? size.width <= theme.breakpoints.values.sm
        ? t('orderState.steps.failed.shortLabel')
        : t('orderState.steps.failed.label')
      : isDelivery
      ? t('orderState.steps.delivered.label')
      : t('orderState.steps.fulfilled.label'),
  ];

  // History
  const history = useHistory();

  // Aux values
  const isDelivered = orderData.status === STATES.DELIVERED;

  // Messages

  const deliveredMessage = t(
    orderData.hasFeedback ? 'orderState.texts.gratitude' : 'orderState.texts.giveUsFeedback.text',
  );

  const orderStatusCamelCase = snakeToCamelCase(orderData.status);

  const deliveryAccordingStateMessage = `${t('orderState.texts.order')} ${t(
    `orderState.steps.${orderStatusCamelCase}.orderText`,
  )}`;

  const deliveryFailedMessage = `${t('orderState.texts.orderPossessive')} ${t(
    'orderState.steps.failed.orderText',
  )}`;

  const pickupFailedMessage = `${t('orderState.text.pickup')} ${t('orderState.steps.failed.text')}`;

  const pickupAccordingStateMessage = `${t('orderState.texts.pickupRouteStarted')} ${
    orderData.companyName
  }
  ${t('orderState.texts.withRequest')} #${orderData.number} ${t(
    `orderState.steps.${orderStatusCamelCase}.text`,
  )}`;

  const pickupIsPreparingMessage = `${t('orderState.texts.pickup')} #${orderData.number} ${t(
    'orderState.texts.from',
  )} ${orderData.companyName}  ${t('orderState.steps.assigned.text')}`;

  const titleBuildOptions = {
    [OPERATIONS.PICKUP]: {
      [STATES.DELIVERED]: isFailed ? pickupFailedMessage : deliveredMessage,
      [STATES.ASSIGNED]: isFailed ? pickupFailedMessage : pickupIsPreparingMessage,
      default: isFailed ? pickupFailedMessage : pickupAccordingStateMessage,
    },
    [OPERATIONS.DELIVERY]: {
      [STATES.DELIVERED]: isFailed ? deliveryFailedMessage : deliveredMessage,
      default: isFailed ? deliveryFailedMessage : deliveryAccordingStateMessage,
    },
  };
  const orderLabel = ` #${orderData.number}`;
  const title =
    titleBuildOptions[orderData.operation][orderData.status] ||
    titleBuildOptions[orderData.operation]['default'];

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item style={{ width: '100%', maxWidth: '626px' }}>
          <TracingOperationHeader
            title={title}
            isDelivery={isDelivery}
            isDelivered={isDelivered}
            orderLabel={orderLabel}
            organization={organization.name}
            qtn={qtn}
          />
          <Grid item className={classes.giveFeedbackButton}>
            {isDelivered && !orderData.hasFeedback && (
              <Button
                id="give-feedback-button"
                variant="contained"
                color="primary"
                className={classes.rounded}
                name="buttonFeedback"
                onClick={() =>
                  history.push(
                    `/feedback?organization=${organizationKey}&qtn=${qtn}&overallSatisfaction=5`,
                  )
                }
              >
                {t('orderState.texts.giveUsFeedback.button')}
              </Button>
            )}
          </Grid>
        </Grid>
        <TracingStepper
          steps={steps}
          activeStep={step}
          isDelivery={isDelivery}
          isFailed={isFailed}
        />
        <Grid className={classes.card} item>
          {organization && (
            <OrderInfoCard
              orderData={orderData}
              organization={organization}
              qtn={qtn}
              organizationKey={organizationKey}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
});

export default TracingOperation;

TracingOperation.propTypes = {
  step: PropTypes.number,
};

TracingOperation.defaultProps = {
  step: 0,
};
