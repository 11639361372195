import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { HERE_APP_ID, HERE_APP_CODE } from '../config';
import { MAP_CONFIG, MAP_STYLES, MAP_THEME, MAP_UI_CONTROLS } from '../config/map';
// Marker svg
import PoiIcon from '../assets/marker.svg';

export default React.memo(function VanillaHereMap({
  app_id = HERE_APP_ID,
  app_code = HERE_APP_CODE,
  version = 3,
  app_apiKey,
  theme,
  style,
  poiPosition,
  devicePosition,
  interactive,
  responsive,
}) {
  // Create a reference to the HTML element we want to put the map on
  const mapRef = React.useRef(null);

  // Other aux refs
  const deviceInitPosition = React.useRef(devicePosition);
  const markerRef = React.useRef(null);
  const groupRef = React.useRef(null);
  const firstRender = React.useRef(true);

  // Platform config
  const configObj = {
    ...(app_apiKey || version === 3 ? { apikey: app_apiKey } : { app_id, app_code }), // Auth approach according to version
    ...(theme && { theme }),
    ...(style && { style }),
  };

  const [platformConfig] = React.useState(configObj);
  const [state, setState] = React.useState({
    hMap: undefined,
  });

  // Draws Track & Trace map objects. Called bellow
  const drawMapObjects = React.useCallback(
    (H, hMap) => {
      // Markers group
      const group = new H.map.Group();
      groupRef.current = group;
      hMap.addObject(group);
      // Setting up markers
      const deviceDivMarker = renderToStaticMarkup(<div className="device-div-marker"></div>);
      const deviceIcon = new H.map.DomIcon(deviceDivMarker);
      const poiIcon = new H.map.Icon(PoiIcon);
      // Markers
      const poiMarker = new H.map.Marker(poiPosition, { icon: poiIcon });
      const deviceMarker = new H.map.DomMarker(deviceInitPosition.current, { icon: deviceIcon });
      markerRef.current = deviceMarker;
      // Add markers to group
      group.addObjects([deviceMarker, poiMarker]);
      hMap.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });
    },
    [poiPosition],
  );

  // Configures UI controllers and event behaviors. Called bellow
  const setupInteractions = React.useCallback(
    (H, hMap, defaultLayers) => {
      if (interactive) {
        // Creates UI and controls
        const ui = H.ui.UI.createDefault(hMap, defaultLayers);
        const zoomControl = ui.getControl('zoom');
        const mapController = ui.getControl('mapsettings');
        const { zoomSettings, layersSettings } = MAP_UI_CONTROLS;
        zoomControl.setAlignment(zoomSettings.position);
        mapController.setDisabled(layersSettings.disabled);
        mapController.setVisibility(layersSettings.visibility);

        // Enable the event system on the map instance:
        const mapEvents = new H.mapevents.MapEvents(hMap);

        // Instantiate the default behavior, providing the mapEvents object:
        new H.mapevents.Behavior(mapEvents);
      }
    },
    [interactive],
  );

  /**
   * Create the map instance
   * While `useEffect` could also be used here, `useLayoutEffect` will render
   * the map sooner
   */
  React.useLayoutEffect(() => {
    // `mapRef.current` will be `undefined` when this hook first runs; edge case that
    if (!mapRef.current) return;
    if (!firstRender.current) return;

    // Gets HERE object
    const H = window.H;

    // Configures platform
    const platform = new H.service.Platform(platformConfig);
    const defaultLayers = platform.createDefaultLayers();

    // Creates map instance
    const hMap = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      ...MAP_CONFIG,
      pixelRatio: window.devicePixelRatio || 1,
    });

    // Tiles theme and custom map style properties
    const tiles = platform.getMapTileService({ type: 'base' });
    const layer = tiles.createTileLayer('maptile', MAP_THEME, 256, 'png', {});
    hMap.setBaseLayer(layer);

    // Map padding in pixels according to viewport
    const mapPadding = responsive ? [50, 50, 100, 50] : [200, 100, 100, 200];
    hMap.getViewPort().setPadding(...mapPadding);

    // Sets UI controllers and events
    setupInteractions(H, hMap, defaultLayers);

    // Setting up basic group with both POI and Driver markers
    drawMapObjects(H, hMap);

    // Saving instances to state
    setState({ hMap });

    firstRender.current = false;

    // add a resize listener to make sure that the map occupies the whole container
    window.addEventListener('resize', () => hMap.getViewPort().resize());

    // This will act as a cleanup to run once this hook runs again.
    // This includes when the component un-mounts
    return () => {
      hMap.dispose();
    };
  }, [mapRef, platformConfig, drawMapObjects, setupInteractions, responsive]); // This will run this hook every time this ref is updated

  // Run on every new device position update
  React.useEffect(() => {
    if (firstRender.current) return;
    if (!markerRef.current) return;
    if (!state.hMap) return;
    // Update device marker position
    markerRef.current.setGeometry(devicePosition);
    state.hMap.getViewModel().setLookAtData({
      bounds: groupRef.current.getBoundingBox(),
    });
  }, [devicePosition, state]);

  return <div className="map" ref={mapRef} style={{ ...MAP_STYLES }} />;
});
