import { withStyles, createTheme, makeStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const ColorlibConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 45,
    [theme.breakpoints.down('sm')]: {
      top: 30,
    },
  },
  active: {
    '& $line': {
      backgroundColor: '#1473F7',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#1473F7',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#9FB3C8',
    borderRadius: 1,
  },
}))(StepConnector);

export const ColorFailedConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 45,
    [theme.breakpoints.down('sm')]: {
      top: 30,
    },
  },
  active: {
    '& $line': {
      backgroundColor: '#9FB3C8',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#9FB3C8',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#9FB3C8',
    borderRadius: 1,
  },
}))(StepConnector);

export const breakpoints = createBreakpoints({});

export const muiTheme = createTheme({
  overrides: {
    MuiStepLabel: {
      label: {
        fontSize: 14,
        fontWeight: 600,
        color: '#9FB3C8',
        '&$active': {
          color: '#9FB3C8',
        },
        '&$completed': {
          fontSize: 14,
          fontWeight: 600,
          color: '#1473F7',
        },
        '&$error': {
          fontSize: 14,
          fontWeight: 600,
          color: 'red !important',
        },
        [breakpoints.down('sm')]: {
          '&$alternativeLabel': {
            fontSize: 10,
            fontWeight: 600,
          },
          '&$active': {
            color: '#9FB3C8',
          },
          '&$completed': {
            color: '#1473F7',
          },
        },
      },
    },
  },
});

export const useStyles = makeStyles(theme => ({
  stepper: {
    width: 735,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      width: '100%',
    },
  },
  stepperMob: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '12px 24px',
    },
  },
  stepLabel: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  rounded: {
    borderRadius: 29,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  failedLabel: {
    color: '#9FB3C8 !important',
  },
}));
