import { useState, useEffect, useCallback, useRef } from 'react';
import api from '../services/api';

const useTracking = ({ params, interval }) => {
  // Params
  const { qtn, organizationKey } = params;

  // Mounting reference
  const didMount = useRef(null);

  //State
  const [devicePosition, setDevicePosition] = useState();
  const [error, setError] = useState(null);

  const trackOrder = useCallback(async () => {
    try {
      const response = await api.traceOrder(qtn, organizationKey);
      const { lat, lng } = response.data.devicePosition;
      setDevicePosition({ lat: parseFloat(lat), lng: parseFloat(lng) });
    } catch (networkError) {
      setError(networkError);
    }
  }, [organizationKey, qtn]);

  // Fetch at start
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      trackOrder();
    }
  }, [trackOrder]);

  // Fetch device data periodically
  useEffect(() => {
    const trackPeriodically = setInterval(() => {
      trackOrder();
    }, interval);
    return () => clearInterval(trackPeriodically);
  }, [qtn, organizationKey, interval, trackOrder]);

  return {
    error,
    devicePosition,
  };
};

export default useTracking;
