import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: 530,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
    },
  },
  backBtn: {
    textAlign: 'left',
    maxWidth: 280,
    justifyContent: 'flex-start',
  },
  title: {
    marginLeft: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
    },
    marginTop: 8,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
