import { useStyles } from './styles';

const InputAutocomplete = ({
  name,
  label,
  onBlur,
  onChange,
  placeholder,
  type,
  value,
  options = [],
  handleUpdateOptions,
  helperText,
}) => {
  const MIN_CHAR_LIMIT = 3;
  const MAX_CHAR_LIMIT = 20;
  const classes = useStyles();

  const handleCallUpdateOptions = () => {
    // Start on three character
    const charLength = value.length;
    const isOverMinLimit = charLength >= MIN_CHAR_LIMIT;
    const isUnderMaxLimit = charLength <= MAX_CHAR_LIMIT;

    if (isOverMinLimit && isUnderMaxLimit) {
      if (handleUpdateOptions) {
        handleUpdateOptions(value);
      } else {
        console.log('there is not handleUpdateOptions defined');
      }
    }
  };

  return (
    <div className={classes.container}>
      {helperText && <span className={classes.helper}>{helperText}</span>}
      <input
        data-testid="autoCompleteInput"
        className={classes.input}
        onKeyUp={handleCallUpdateOptions}
        list={name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        type={type}
        autoComplete="off"
      />
      <label className={classes.label} htmlFor={name}>
        {label}
      </label>
      <datalist id={name} data-testid="listOptions">
        {options &&
          options.map(option => <option data-testid="autoOptions" key={option} value={option} />)}
      </datalist>
    </div>
  );
};

export default InputAutocomplete;
