import * as Yup from 'yup';

const rescheduleSchema = Yup.object().shape({
  deliveryDate: Yup.date()
    .default(() => new Date())
    .required('reschedule.form.errors.required'),
  timeZone: Yup.string(),
});

export default rescheduleSchema;
