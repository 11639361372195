import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    position: 'fixed',
    width: '100vw',
    zIndex: 999,
  },
  wrapper: {
    display: 'flex',
    maxWidth: 626,
    marginTop: 30,
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: 450,
    },
    [theme.breakpoints.down('xs')]: {
      width: 327,
    },
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 99,
    cursor: 'pointer',
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      top: 0,
    },
  },
  parent: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
}));
