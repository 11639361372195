import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useStyles } from './styles';

const TableDetails = data => {
  const classes = useStyles();
  return (
    <>
      <TableContainer
        className={classes.tableContainer}
        data-testid="tableDetails"
        data-test="tableDetails"
      >
        <Table className={classes.table} size="small" aria-label="simple table">
          <TableBody>
            {data.data.map((row, key) => (
              <TableRow key={key}>
                <TableCell style={{ borderBottom: 'none' }} component="th" scope="row">
                  <p className={classes.tableCellText}>{row.name.toUpperCase()}</p>
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }} align="right">
                  {row.qty}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDetails;
