import React, { useState } from 'react';
import Context from '../context';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContextProvider = ({ children }) => {
  // Getting query params
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [state, setState] = useState({
    organization: null,
    qtn: query.get('qtn'),
    organizationKey: query.get('organization'),
    orderData: null,
    mobileUrl: null,
  });

  // Context value is composed by both state and mutator
  const value = { state, setState };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
