import React, { useContext, useState, useMemo } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { CircularProgress, InputBase } from '@material-ui/core';

import Context from '../../context';
import api from '../../services/api';
import { useStyles } from './Styles';
import { STATES } from '../../config/enums';
import TryAgainError from '../../components/TryAgainError';
import OrderInfoCard from '../../components/OrderInfoCard';
import EmoticonRateBar from '../../components/EmoticonsRateBar';
import FeedbackRateQuestions from '../../components/FeedbackRateQuestions';
import { useOrganization, useOrderData, useValidParams } from '../../hooks';

export default function Feedback() {
  //Styles
  const classesFeedback = useStyles();

  //Translate
  const { t } = useTranslation();

  // Getting query params
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const initialSatisfaction = Number(query.get('overallSatisfaction')) || 0;

  // State
  const [overallSatisfaction, setOverallSatisfaction] = useState(initialSatisfaction);
  const [punctualitySatisfaction, setPunctuality] = useState(0);
  const [serviceSatisfaction, setService] = useState(0);
  const [productSatisfaction, setProduct] = useState(0);
  const [comment, setComment] = useState('');
  const [postError, setPostError] = useState(false);

  //Context
  const context = useContext(Context);
  const { qtn, organizationKey, orderData, organization } = context.state;
  const questions = [
    {
      name: 'star-punctuality',
      label: organization?.feedbackQuestions?.feedbackOne || t('feedBack.punctuality'),
      rate: punctualitySatisfaction,
      setRate: setPunctuality,
    },
    {
      name: 'star-quality',
      label: organization?.feedbackQuestions?.feedbackTwo || t('feedBack.product'),
      rate: productSatisfaction,
      setRate: setProduct,
    },
    {
      name: 'star-service',
      label: organization?.feedbackQuestions?.feedbackThree || t('feedBack.service'),
      rate: serviceSatisfaction,
      setRate: setService,
    },
  ];

  // Fetch data
  const { error: errLoadingOrder, isLoading: isOrderLoading } = useOrderData(context);
  const { error: errLoadingOrg, isLoading: isOrgLoading } = useOrganization(context);

  // Validate params
  const { paramsValid } = useValidParams({ organizationKey, qtn });

  const isDisabled = useMemo(() => !overallSatisfaction, [overallSatisfaction]);

  // Loading state
  const isLoading = useMemo(() => isOrgLoading || isOrderLoading, [isOrgLoading, isOrderLoading]);

  // Error state
  const hasError = useMemo(
    () => errLoadingOrg || errLoadingOrder,
    [errLoadingOrg, errLoadingOrder],
  );

  //History
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const feedbackData = {
        overallSatisfaction,
        punctualitySatisfaction,
        serviceSatisfaction,
        productSatisfaction,
        comment,
      };
      const payload = {
        qtn,
        organizationKey,
        ...feedbackData,
      };
      const response = await api.postFeedback(payload);
      if (response.data && response.data.success) {
        context.setState(prev => ({ ...prev, orderData: null }));
        history.push(`/trace?qtn=${qtn}&organization=${organizationKey}`);
      } else {
        setPostError(true);
      }
    } catch (err) {
      setPostError(true);
    }
  };

  const handleCommentChange = event => {
    setComment(event.target.value);
  };

  const handleEmoticonRateChange = (event, newValue) => setOverallSatisfaction(newValue);

  // Semantics redirects
  const missingQtn = !qtn;
  const missingClientKey = !organizationKey;
  const notFulfilled = orderData?.status !== STATES.DELIVERED;
  const hasFeedback = orderData?.hasFeedback;

  // Redirects
  if (missingQtn || !paramsValid || hasError)
    return <Redirect to={`/order-not-found?organization=${organizationKey}&qtn=${qtn}`} />;
  else if (missingClientKey) return <Redirect to={'/missing-client-key'} />;
  else if (orderData && (notFulfilled || hasFeedback))
    return <Redirect to={`/trace?qtn=${qtn}&organization=${organizationKey}`} />;
  return (
    <>
      <Backdrop className={classesFeedback.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {orderData && organization && (
        <Grid container justifyContent="center">
          <div className={classesFeedback.cardDel}>
            <OrderInfoCard orderData={orderData} organization={organization} isFeedback qtn={qtn} />
          </div>
          <EmoticonRateBar
            label={organization?.feedbackQuestions?.generalFeedback}
            indexIconSelected={overallSatisfaction}
            handleSelectIcon={handleEmoticonRateChange}
          />
          <Grid className={classesFeedback.space2}>
            <Card className={classesFeedback.root}>
              <CardContent>
                <FeedbackRateQuestions questions={questions} />
                <Grid container justifyContent="center">
                  <Typography className={classesFeedback.title2}>
                    {t('feedBack.improvement')}
                  </Typography>
                  <div className={classesFeedback.contain}>
                    <InputBase
                      inputProps={{ 'aria-label': 'feedback-comments' }}
                      className={classesFeedback.text}
                      fullWidth={true}
                      rows="5"
                      data-testid="feedback-comments"
                      multiline
                      id="Feedback-Comments"
                      value={comment}
                      required
                      onChange={handleCommentChange}
                      disabled={isDisabled}
                    />
                  </div>
                </Grid>
              </CardContent>
              <CardActions className={classesFeedback.cardAction}>
                <Button
                  onClick={handleSubmit}
                  id="submit-feedback-button"
                  data-test="submit-feedback"
                  type="submit"
                  className={classesFeedback.button}
                  variant="contained"
                  color="primary"
                  disabled={isDisabled}
                >
                  {t('feedBack.send')}
                </Button>
              </CardActions>
              <Grid>{postError && <TryAgainError />}</Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
