import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';
import { useStyles } from './styles';
import ClientKeyErrorImg from '../../assets/errors/client-key-error.png';

export default function MissingClientKey() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={4}
      direction="column"
      justifyContent="center"
      alignItems="center"
      data-test="missing-client-key"
    >
      <Grid item className={classes.img}>
        <img width={150} height={150} alt="client-key-error" src={ClientKeyErrorImg} />
      </Grid>
      <Grid item>
        <Typography align="center" className={classes.title} variant="h5">
          {t('missingClientKey.title')}
        </Typography>
      </Grid>
    </Grid>
  );
}
