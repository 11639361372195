import React, { useContext, useMemo } from 'react';
import { Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button, Box, InputLabel, MenuItem, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import OutlinedSelect from '../OutlinedSelect';
import InputCalendarDatePicker from '../InputCalendarDatePicker';
import useWindowSize from '../../hooks/useWindowSize';
import { snakeToCamelCase } from '../../utils';
import Context from '../../context';

const ScheduleForm = props => {
  const { goBack } = useHistory();
  const context = useContext(Context);
  const { orderData, organization } = context.state;
  const classes = useStyles();
  const { t } = useTranslation();
  const winSize = useWindowSize();
  const theme = useTheme();
  const isMobile = winSize.width <= theme.breakpoints.values.sm;
  const rescheduleConfigData = props.config;
  const handleDayPickerChange = form => value => form.setFieldValue('deliveryDate', value);
  const renderTimeframe = useMemo(() => {
    return (
      rescheduleConfigData.timeframes.length > 0 &&
      organization?.ctaConfig[snakeToCamelCase(orderData.status)]?.canSelectTimeframe
    );
  }, [rescheduleConfigData, organization, orderData]);
  return (
    <Form className={classes.container}>
      <InputLabel style={{ paddingBottom: 8 }} id="demo-simple-select-label">
        {t('reschedule.form.delivery')}
      </InputLabel>
      <Field name="deliveryDate">
        {({ field, form, meta }) => {
          return (
            <InputCalendarDatePicker
              {...field}
              className={classes.input}
              handleChange={handleDayPickerChange(form)}
              name="deliveryDate"
              minDayLimit={rescheduleConfigData.minRescheduleTime}
              maxDayLimit={rescheduleConfigData.maxRescheduleTime}
              nonWorkingDays={rescheduleConfigData.nonWorkDays}
              availableWeekDays={rescheduleConfigData.weekDays}
            />
          );
        }}
      </Field>
      {renderTimeframe && (
        <>
          <InputLabel
            style={{ paddingBottom: 8 }}
            id="demo-simple-select-label"
            data-testid="timeframeLabel"
          >
            {t('reschedule.form.timeZone')}
          </InputLabel>
          <Field
            className={classes.input}
            label={t('reschedule.form.timeZone')}
            type="text"
            name="timeZone"
            initialValues={props.initialValues}
            component={OutlinedSelect}
            data-testid="timeframeLabel"
          >
            {rescheduleConfigData.timeframes.map((timeFrame, index) => (
              <MenuItem value={index}>{`${timeFrame.from}hs - ${timeFrame.to}hs`}</MenuItem>
            ))}
          </Field>
        </>
      )}
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'flex-end'}
      >
        <Button
          id="reschedule-cancel-button"
          type="button"
          onClick={() => goBack()}
          data-testid="rescheduleCancelButton"
        >
          {t('address.form.cancel')}
        </Button>
        <Button
          id="reschedule-continue-button"
          type="button"
          variant="contained"
          color="primary"
          className={classes.okButton}
          onClick={props.handleSubmit}
          data-testid="rescheduleConfirmButton"
        >
          {t('address.form.ok')}
        </Button>
      </Box>
    </Form>
  );
};

export default ScheduleForm;
