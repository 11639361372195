import { useState, useEffect, useCallback, useRef } from 'react';
import { DEV_HOSTS } from '../config/enums';
import api from '../services/api';

/**
 * @param {Context} context
 * @param {Number} interval
 * @returns {Object} {isLoading, error}
 */

const useMobilePath = context => {
  // Context
  const { qtn, organizationKey, mobileUrl } = context.state;
  const { setState } = context;

  // Mounting reference
  const didMount = useRef(null);
  //State
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMobileUrlPath = useCallback(async () => {
    try {
      // Only show loading state once
      if (!didMount.current) setIsLoading(true);
      const baseUrl = Object.values(DEV_HOSTS).some(host => window.location.href.includes(host))
        ? 'https://flash-tracking-test.quadminds.com'
        : window.location.origin;
      const response = await api.fetchMobileUrlPath(qtn, organizationKey, baseUrl);
      setState(prev => ({ ...prev, mobileUrl: response.data.url }));
      setIsLoading(false);
    } catch (networkError) {
      setIsLoading(false);
      setError(networkError);
    }
  }, [organizationKey, qtn, setState]);

  // Fetch order data
  useEffect(() => {
    if (mobileUrl) return;
    if (!organizationKey) return;
    if (!qtn) return;
    // Fires data fetch at mount for first time
    if (!didMount.current) {
      // Actual call
      fetchMobileUrlPath();
      didMount.current = true;
    }
  }, [organizationKey, qtn, mobileUrl, fetchMobileUrlPath]);

  return { error, isLoading };
};
export default useMobilePath;
