import { Form, Field, ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';
import { memo, useCallback, useState } from 'react';
import { Button, Box, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import InputOutlinedLabeled from '../InputOutlinedLabeled';
import LocationInput from '../LocationInput';
import useWindowSize from '../../hooks/useWindowSize';

const AddressForm = props => {
  const { goBack } = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const winSize = useWindowSize();
  const [halfStep, setHalfStep] = useState(true);
  const isMobile = winSize.width <= theme.breakpoints.values.sm;
  const hasToHideOnMobile = useCallback(
    (step = 1) => {
      if (winSize.width <= theme.breakpoints.values.sm) {
        const configStep = {
          1: halfStep,
          2: !halfStep,
          3: winSize.width <= theme.breakpoints.values.sm,
        };
        return configStep[step];
      } else {
        return true;
      }
    },
    [halfStep, winSize, theme.breakpoints.values.sm],
  );
  return (
    <Form className={classes.container}>
      {hasToHideOnMobile(1) && (
        <>
          <Field
            label={t('address.form.address')}
            type="text"
            name="address"
            helperText={t('address.form.helper')}
            setFieldValue={props.setFieldValue}
            initialValues={props.initialValues['address']}
            as={LocationInput}
          />
          <ErrorMessage name="address">
            {msg => <span className={classes.error}>{t(msg)}</span>}
          </ErrorMessage>
        </>
      )}
      {hasToHideOnMobile(2) && (
        <>
          <Field
            type="text"
            label={t('address.form.details')}
            name="alternativeAddress"
            component={InputOutlinedLabeled}
            data-testid="alternativeAddressInput"
          />
          <Field
            type="text"
            label={t('address.form.comments')}
            multiline={true}
            name="comments"
            component={InputOutlinedLabeled}
          />
          <Field
            type="text"
            label={t('address.form.phone')}
            name="phone"
            component={InputOutlinedLabeled}
          />
          <ErrorMessage name="phone">
            {msg => <span className={classes.error}>{t(msg)}</span>}
          </ErrorMessage>
        </>
      )}
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'flex-end'}
      >
        {isMobile ? (
          <>
            <Button
              id="change-address-continue-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={halfStep ? () => setHalfStep(false) : props.handleSubmit}
              className={classes.okButton}
              disabled={halfStep ? false : !props.isValid}
            >
              {t('address.form.next')}
            </Button>
            <Button
              id="change-address-cancel-button"
              type="button"
              onClick={halfStep ? goBack : () => setHalfStep(true)}
            >
              {t('address.form.cancel')}
            </Button>
          </>
        ) : (
          <>
            <Button
              id="change-address-cancel-button"
              type="button"
              onClick={goBack}
              data-testid="cancelAddressButton"
            >
              {t('address.form.cancel')}
            </Button>
            <Button
              id="change-address-continue-button"
              type="button"
              variant="contained"
              color="primary"
              onClick={props.handleSubmit}
              className={classes.okButton}
              disabled={!props.isValid}
              data-testid="confirmAddressButton"
            >
              {t('address.form.ok')}
            </Button>
          </>
        )}
      </Box>
    </Form>
  );
};

export default memo(AddressForm);
