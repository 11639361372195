import { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from './styles';
import closeIcon from '../../assets/close-icon.png';

const CustomModal = forwardRef(({ isOpen, onClose, children }, ref) => {
  const classes = useStyles();
  if (!isOpen) return null;
  return createPortal(
    <div ref={ref} className={classes.container}>
      <div className={classes.wrapper}>
        <img onClick={onClose} className={classes.close} src={closeIcon} alt="close-modal" />
        {children}
      </div>
    </div>,
    document.getElementById('modal'),
  );
});

export default CustomModal;
