import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    top: 20,
    left: 20,
    width: 300,
  },
}));
