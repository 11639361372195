import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 600,
    height: 60,
    // Responsive
    [theme.breakpoints.down('sm')]: {
      width: 350,
      height: 50,
    },
    border: '1px solid #9FB3C8',
    boxShadow: '0px 4px 4px #1717172E',
    borderRadius: 37,
  },
  inputBase: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 18,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none',
      },
  },
  input: {
    '&::placeholder': {
      fontSize: 18,
      // Responsive
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  iconButton: {
    paddingLeft: theme.spacing(2),
    height: 50,
    color: '#94A7AC',
  },
  helperText: {
    margin: theme.spacing(2, 6),
    fontSize: 16,
    // Responsive
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  button: {
    borderRadius: 29,
    width: 125,
    height: 50,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 45,
      fontSize: 14,
    },
  },
}));
