import React, { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Context from '../../context';
import { useOrderData, useOrganization, useTracking, useValidParams } from '../../hooks';

import TrackInfo from '../../components/TrackInfo';
import { HERE_API_KEY, TRACK_INTERVAL } from '../../config';

import TryAgainError from '../../components/TryAgainError';
import VanillaHereMap from '../../components/VanillaHereMap';
import { STATES } from '../../config/enums';
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  map: {
    overflow: 'hidden',
  },
  mapError: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function LiveTracking() {
  // Styles
  const classes = useStyles();

  //Responsive design
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  // Context
  const context = useContext(Context);
  const { qtn, organizationKey, orderData, organization } = context.state;

  // Validate params
  const { paramsValid } = useValidParams({ organizationKey, qtn });

  // Order status refresh interval

  // Fetch data
  const { isLoading: isOrgLoading, error: errLoadingOrg } = useOrganization(context);
  const { isLoading: isOrderLoading, error: errLoadingOrder } = useOrderData(context);
  const { devicePosition } = useTracking({
    params: { qtn, organizationKey },
    interval: TRACK_INTERVAL ?? 10000,
  });
  // Semantics redirects
  const missingClientKey = !organizationKey;
  const missingQtn = !qtn;

  // Map data ready
  const mapDataReady = useMemo(
    () => orderData && organization && Boolean(devicePosition),
    [orderData, organization, devicePosition],
  );

  // Loading state
  const isLoading = useMemo(
    () => isOrgLoading || isOrderLoading || !mapDataReady,
    [isOrgLoading, isOrderLoading, mapDataReady],
  );

  // Loading state
  const hasError = useMemo(
    () => errLoadingOrg || errLoadingOrder,
    [errLoadingOrg, errLoadingOrder],
  );

  // Render
  if (missingQtn || !paramsValid || errLoadingOrder?.response?.status === 404)
    return <Redirect to={`/order-not-found?organization=${organizationKey}&qtn=${qtn}`} />;
  else if (missingClientKey) return <Redirect to={'/missing-client-key'} />;
  else if (orderData?.status === STATES.DELIVERED || !orderData?.enableLiveTrack)
    return <Redirect to={`/trace?qtn=${qtn}&organization=${organizationKey}`} />;
  else if (hasError)
    return (
      <div className={classes.mapError}>
        <TryAgainError />
      </div>
    );
  else
    return (
      <div data-test="live-tracking">
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {mapDataReady && (
          <div data-test="live-tracking-content">
            <TrackInfo responsive={matches} data={{ organization, orderData }} />
            <VanillaHereMap
              responsive={matches}
              className={classes.map}
              app_apiKey={HERE_API_KEY}
              data-test="live-tracking-map"
              poiPosition={orderData.poiPosition}
              devicePosition={devicePosition}
            />
          </div>
        )}
      </div>
    );
}
